<template>
  <a-modal
    id="ai-annotation-modal"
    centered
    destroy-on-close
    :style="{ width: '40%' }"
    ok-text="Revert"
    :ok-button-props="{ loading: loading }"
    :visible="showAiAnnotation"
    title="Revert Objects"
    :mask-closable="false"
    @ok="handleOk"
    @cancel="handleAiAnnotationCancel"
  >
    <a-space size="40" direction="vertical" class="w-100">
      <a-typography-title :level="4" class="ml-2 mt-2">
        Task Objects List
      </a-typography-title>
      <a-table
        class="mt-3"
        :data-source="taskObjects"
        :row-selection="{
          type: 'checkbox',
          selectedRowKeys: selectedObjects,
          onChange: onObjectSelectionChange,
        }"
        :columns="[
          {
            title: 'Objects',
            dataIndex: 'name',
            key: 'name',
          },
        ]"
        :row-key="'id'"
        :scroll="{ y: '40vh', x: true }"
        :pagination="false"
      >
        <template #emptyText>
          <div
            id="no-task-objects-found"
            style="height: 35vh"
            class="d-flex flex-column align-items-center justify-content-center"
          >
            <laptop-outlined style="font-size: 40px" />
            <span class="mt-2">No Task Objects Found</span>
          </div>
        </template>
      </a-table>
    </a-space>
  </a-modal>
</template>

<script>
import { LaptopOutlined } from '@ant-design/icons-vue';
import dayjs from 'dayjs';
import dateHelper from 'src/components/shared/Helpers/dateHelper';
import ModelTypeService from 'src/services/modelTypes';
import taskObjectsMarking from 'src/services/taskObjectsMarking';
import { mapGetters } from 'vuex';
import { ModelVersionAiAnnotationColumns, modelTypeSelection } from './config';

export default {
  components: {
    LaptopOutlined,
  },

  inject: ['toast'],

  props: {
    showAiAnnotation: { type: Boolean, default: false, required: true },
    selectedImages: { type: Array, default: () => {}, required: true },
    taskObjectsList: { type: Array, default: () => {}, required: true },
    presignedUrlList: { type: Array, default: () => {}, required: true },
    selectedAnnotation: { type: Array, default: () => {}, required: true },
    selectedFilePath: { type: Array, default: () => {}, required: true },
  },

  emits: ['setShowAiAnnotation'],

  setup() {
    return {
      ModelVersionAiAnnotationColumns,
      dateHelper,
      modelTypeSelection,
    };
  },

  data() {
    return {
      step: 1,
      selectedRow: null,
      selectedModelTypeObject: null,
      modelChoiceId: null,
      selectedObjects: [],
      selectObjectsName: [],
      taskObjects: [],
      TaskObjectColumns: [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
      ],
      loading: false,
    };
  },

  computed: {
    ...mapGetters([
      'modelVersionsList',
      'taskName',
      'selectedTask',
      'organization',
      'modelChoice',
    ]),

    sortedModelVersionList() {
      if (!this.modelVersionsList.length) return [];
      const sorted = this.modelVersionsList
        .filter((mv) => !mv.is_deleted && mv.model_type === this.modelChoiceId)
        .sort((a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1));
      return sorted;
    },

    rowSelection() {
      return {
        type: 'radio',
        selectedRowKeys: [this.selectedRow],
        onChange: this.onRowSelect,
      };
    },
  },

  watch: {
    async showAiAnnotation(value) {
      if (value && !this.modelChoiceId) await this.setModelTypeId();
    },
    taskObjectsList(value) {
      this.taskObjects = value;
    },
  },

  methods: {
    async setModelTypeId() {
      if (!this.modelChoice) return;
      const [error, data] = await ModelTypeService.fetchModelTypes();
      if (error || !data?.length) return;
      const modelChoiceObj = data.find((obj) => obj.name === this.modelChoice);
      if (!modelChoiceObj) return;
      this.modelChoiceId = modelChoiceObj.id;
    },

    handleOk() {
      this.startAiAnnotationProcess();
    },

    onObjectSelectionChange(selectedRowKeys) {
      this.selectedObjects = selectedRowKeys;
      const selectedObjectNames = this.taskObjects
        .filter((obj) => selectedRowKeys.includes(obj.id))
        .map((obj) => obj.name);
      this.selectObjectsName = selectedObjectNames;
    },

    getSelectedImageKeys() {
      if (!this.selectedImages) return [];
      let keys = [];
      for (let key of Object.keys(this.selectedImages)) {
        if (this.selectedImages[key]) keys.push(Number(key));
      }
      return keys;
    },

    async startAiAnnotationProcess() {
      const payload = {
        presigned_urls: this.selectedAnnotation,
        object_list: this.selectObjectsName,
        bucket_name: this.organization + '-training',
        file_path: this.selectedFilePath,
      };
      this.loading = true;
      const [error, data] = await taskObjectsMarking.revertObjectAnnotations(
        payload
      );
      this.loading = false;
      if (error) {
        this.toast.error('Failed to revert the selected object.');
      } else {
        this.toast.success('Objects successfully reverted.');
      }

      this.handleAiAnnotationCancel();
    },

    onRowSelect(selectedRowKeys) {
      this.selectedRow = selectedRowKeys[0];
    },

    handleAiAnnotationCancel() {
      this.selectedRow = null;
      this.selectedModelTypeObject = null;
      this.selectedObjects = [];
      this.step = 1;
      this.$emit('setShowAiAnnotation', false);
    },
  },
};
</script>
