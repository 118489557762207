<template>
  <a-modal
    :visible="showODSetupModal"
    width="100%"
    class="object-detection-modal"
    wrap-class-name="full-modal"
    destroy-on-close
    :body-style="{
      display: 'flex',
      flexDirection: 'column',
      padding: '1em',
      overflow: 'auto'
    }"
    :after-close="
      () => {
        currentStep = 0;
      }
    "
  >
    <template #closeIcon>
      <close-circle-outlined
        style="color: red"
        @click="
          showModal({
            modalType: types.SET_SHOW_OD_SETUP_MODAL,
            value: false
          })
        "
      />
    </template>

    <template #title>
      <a-steps :current="currentStep" size="small" v-if="!loading">
        <a-step
          v-for="(item, index) in stepsToPerform"
          :key="item.title"
          :title="item.title"
          @click="handlePageChange(index)"
        />
      </a-steps>
    </template>

    <div v-if="!loading" class="steps-content h-100">
      <template v-for="(step, index) in stepsToPerform">
        <component
          :is="step.component"
          v-if="currentStep === index"
          :key="step.title"
          :pipeline-step="currentStep"
          @nextStep="gotoNextStep"
          @handleDone="handleDone"
          @updateStep="updateStep"
        />
      </template>
    </div>

    <div v-else class="h-100">
      <a-spin class="m-auto" size="large" />
    </div>

    <template #footer>
      <a-space class="w-100 d-flex justify-content-end">
        <a-button v-if="currentStep > 0" @click="gotoPreviousStep">
          <template #icon>
            <component :is="stepsToPerform[currentStep].prevButton?.icon" />
          </template>
          {{ stepsToPerform[currentStep].prevButton?.text }}
        </a-button>

        <a-button
          type="primary"
          @click="handleClickNextStep"
          style="margin-left: auto !important"
          class="next-step-button"
        >
          <template #icon>
            <component :is="stepsToPerform[currentStep].nextButton.icon" />
          </template>
          {{ stepsToPerform[currentStep].nextButton.text }}
        </a-button>
      </a-space>
    </template>
  </a-modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import types from 'src/store/mutation-types';
import {
  CloseCircleOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckOutlined
} from '@ant-design/icons-vue';
import TrainModel from './TrainModel.vue';
import DefineObjectsAndStaticRegion from './DefineObjectsAndStaticRegion.vue';
import LabelObjects from './LabelObjects.vue';
import LabelStaticRegions from './LabelStaticRegions.vue';
import LabelPlaneRegions from './LabelPlaneRegions.vue';
import AssociateSteps from './AssociateSteps.vue';
import RelationSteps from './RelationSteps.vue';
import StepCompletionCheck from './StepCompletionCheck.vue';
import { steps, getTaskODSteps } from './config';

export default {
  components: {
    CloseCircleOutlined,
    TrainModel,
    DefineObjectsAndStaticRegion,
    LabelObjects,
    LabelStaticRegions,
    LabelPlaneRegions,
    AssociateSteps,
    StepCompletionCheck,
    RelationSteps,
    ArrowLeftOutlined,
    ArrowRightOutlined,
    CheckOutlined
  },
  setup() {
    return {
      stepsToPerform: [...steps],
      types
    };
  },

  created() {
    this.setTaskSteps();
  },

  async updated() {
    await this.fetchTaskDetails(this.selectedTask);
  },

  watch: {
    isTask3d(val) {
      this.setTaskSteps();
    }
  },

  data() {
    return {
      currentStep: 0,
      loading: true
    };
  },
  computed: {
    ...mapGetters(['showODSetupModal', 'selectedTask', 'isTask3d'])
  },
  methods: {
    ...mapActions(['showModal', 'fetchTaskDetails']),

    updateStep(gotoStep) {
      this.currentStep += gotoStep;
    },

    setTaskSteps() {
      this.loading = true;
      this.stepsToPerform = getTaskODSteps(this.isTask3d);
      this.loading = false;
    },

    // async get3dSupportInfo() {
    //   if (!this.selectedTask) return;
    //   let is3dSupport = false;
    //   var [error, data] = await DepthRegionService.isTaskSupport3D(
    //     this.selectedTask
    //   );
    //   if (error) {
    //     is3dSupport = false;
    //   }
    //   is3dSupport = data?.is_3d;
    //   this.setIs3dTask(is3dSupport);
    //   if (is3dSupport == false) {
    //     this.stepsToPerform = steps.filter(
    //       (s) => s.key !== 'label_plane_regions'
    //     );
    //   } else {
    //     this.stepsToPerform = [...steps];
    //   }
    // },

    gotoNextStep() {
      this.currentStep++;
    },

    gotoPreviousStep() {
      this.currentStep--;
    },

    handleClickNextStep() {
      if (this.currentStep < this.stepsToPerform.length - 1)
        this.gotoNextStep();
      else this.handleDone();
    },

    handleTrainModel() {
      this.$router.push({path: "/user/train"});
    },

    handlePageChange(index) {
      this.currentStep = index;
      if (this.currentStep === this.stepsToPerform.length - 1) {
        this.handleTrainModel()
      }
    },
    handleDone() {
      this.showModal({
        modalType: types.SET_SHOW_OD_SETUP_MODAL,
        value: false
      });
    },

    afterClose() {}
  }
};
</script>
<style>
.steps-content {
  border-radius: 6px;
}

.object-detection-modal
  > .ant-modal-content
  > .ant-modal-header
  > .ant-modal-title {
  padding: 0 2em !important;
}

.ant-steps-item {
  position: relative;
  display: inline-block;
  flex: auto;
  vertical-align: top;
  text-overflow: ellipsis;
}
.next-step-button {
  display: flex;
  align-items: center;
}

.next-step-button .anticon {
  order: 2;
  margin-left: 8px;
}

@media (max-width: 1201px) {
  .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    white-space: inherit;
  }
  .ant-steps-item .ant-steps-item-content {
    display: block;
  }

  .ant-steps-item .ant-steps-item-icon {
    margin: 0 40px;
  }
}
</style>
