<template>
  <a-row class="p-4" :gutter="[8, 8]">
    <a-col span="6">
      <a-select
        v-model:value="task_id"
        class="w-100 d-flex"
        show-search
        placeholder="Select Operation"
        :options="taskOptions"
        :filter-option="customFilterOption"
        :loading="isFetchingTasks"
      />
    </a-col>
    <a-col span="6">
      <a-select
        v-model:value="selectedDevice"
        class="w-100 d-flex"
        show-search
        placeholder="Select Device(s)"
        :options="deviceOptions"
        :filter-option="customFilterOption"
        :loading="isFetchingDevices"
      />
    </a-col>
    <a-col span="6"
      ><a-button type="primary" @click="displayTags">
        Update
      </a-button></a-col
    >
    <a-col span="6" class="d-flex justify-content-end align-items-center mb-3">
      <a-button type="primary" @click="showAddTageModal">
        <template #icon>
          <plus-outlined />
        </template>
        Add Tags
      </a-button>
    </a-col>
    <a-col span="24">
      <tag-list
        :tags="tags"
        :isTagsLoading="isTagsLoading"
        :tagCount="tagCount"
        :currentPage="currentPage"
        :tag-address-tree="tagAddressTree"
        @getTags="getTags()"
        @refreshTagList="refreshTagList()"
        @setcurrentPage="setcurrentPage"
      />
    </a-col>
  </a-row>
  <add-tag-modal
    :visible-add-tag-modal="visibleAddTagModal"
    :tag-address-tree="tagAddressTree"
    @close="visibleAddTagModal = false"
    @getTags="getTags()"
    @refreshTagList="refreshTagList"
  />
</template>

<script>
import { ref } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import TagList from './TagList.vue';
import AddTagModal from './AddTagModal.vue';
import { PlusOutlined } from '@ant-design/icons-vue';
import IoTService from '../../../../services/iot';
import TaskService from 'src/services/tasks';
import { getSortedTask } from 'src/utils/task';
import { getSortedDeviceForTag } from 'src/utils/device';
import fcm from 'src/services/fcm';
import { messaging, handleMessage } from 'src/utils/fcm';
import DeviceConfig from 'src/config/device-config';
import FcmMixin from 'src/mixins/FcmMixin';
import { validRoutesName } from 'src/config/fcm-config';

export default {
  components: {
    PlusOutlined,
    AddTagModal,
    TagList,
  },
  mixins: [FcmMixin],
  inject: ['toast'],

  data() {
    return {
      tags: [],
      isTagsLoading: false,
      visibleAddTagModal: ref(false),
      task_id: null,
      isLoadingTasks: false,
      taskTags: [],
      selectedDevice: null,
      tagCount: 0,
      currentPage: 1,
      discoverDeviceLoading: false,
      tagAddressTree: {},
    };
  },
  watch: {
    task_id(value) {
      this.task_id = value;
    },
    selectedDevice(value) {
      this.selectedDevice = value;
    },
    currentPage(value) {
      this.fetchTagsByTaskAndDevice(value);
    },
  },
  created() {
    // this.getTasks();
    this.getAllTasks();
    this.getOrgDevices(this.organization);
    handleMessage(messaging, this.onMessageReceived);
  },
  unmounted() {
    this.emitter.off('discoverDevices', this.populateTreeData);
  },

  computed: {
    ...mapGetters([
      'isFetchingTasks',
      'isFetchingDevices',
      'allTasks',
      'organization',
      'devices',
      'fcmToken',
    ]),
    taskOptions() {
      return getSortedTask(this.allTasks);
    },
    deviceOptions() {
      let devices = getSortedDeviceForTag(this.devices);
      return devices;
    },
  },

  methods: {
    ...mapActions(['getAllTasks', 'getOrgDevices']),
    showAddTageModal() {
      this.visibleAddTagModal = true;
      this.discoverDevices();
    },
    onMessageReceived(payload) {
      if (!validRoutesName.includes(this.$route.name)) return;
      const _data = this.getMessageData(payload);
      console.log(_data);
      if (!_data) return;

      let types = [DeviceConfig.discover_devices_opcua];

      const handlers = {
        [DeviceConfig.discover_devices_opcua]: this.populateTreeData,
      };

      const { type } = payload?.data;
      if (types.includes(type)) handlers[type](_data);
    },
    populateTreeData(data) {
      console.log('populateTreeData', data);
      if (data && this.discoverDeviceLoading) {
        this.discoverDeviceLoading = false;
        this.tagAddressTree = data;
      }
      console.log(this.tagAddressTree);
    },
    async discoverDevices() {
      console.log('discover');
      this.discoverDeviceLoading = true;
      const opcua_tag_payload = {
        data: { device_token: this.fcmToken },
        type: 'discover_devices_opcua',
        message_time: new Date().toISOString(),
        platform: 'portal',
        organization: this.organization,
        device_id: '',
      };
      await fcm.sendMessageToRabbitMQ(opcua_tag_payload, false);
    },
    customFilterOption(input, option) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    async displayTags() {
      this.discoverDevices();
      this.fetchTagsByTaskAndDevice();
    },
    async fetchTagsByTaskAndDevice(page = 1) {
      if (page !== this.currentPage) this.currentPage = page;
      if (!this.task_id || !this.selectedDevice) {
        return this.toast.error('Failed to retrieve tasks. Try again');
      }
      this.isTagsLoading = true;
      const [error, data] = await IoTService.getOPCTagByTaskAndDevice(
        this.task_id,
        this.selectedDevice,
        page
      );
      if (error) {
        this.isTagsLoading = false;
        return this.toast.error('Failed to retrieve tasks. Try again', {
          timeout: 3000,
        });
      }
      if (data.count <= 10) {
        this.currentPage = 1;
      }
      this.tags = data.results;
      this.tagCount = data.count;
      this.isTagsLoading = false;
    },
    setcurrentPage(page) {
      this.currentPage = page;
    },
    refreshTagList() {
      this.fetchTagsByTaskAndDevice();
    },

    async getTags() {
      console.log('called');
      this.isTagsLoading = true;
      const [error, tags] = await IoTService.getOPCTags();

      if (error) {
        this.isTagsLoading = false;
        return this.toast.error('Failed to load OPC Tags.', {
          timeout: 3000,
        });
      }
      console.log(tags.results);
      this.tags = tags.results;
      this.isTagsLoading = false;
    },
  },
};
</script>
