import { deepClone } from 'src/utils/task';
import { uuid } from 'vue-uuid';
import {
  calculateRectangleMidPoint,
  createNewCircleConfig,
  createNewRectangleConfig,
  createNewTextConfig,
  normalizeDimension,
  normalizePolygonPoints,
} from '../components/user-panel/pages/LabelData/ObjectAnnotation/helpers';
import * as OBJECT from '../components/user-panel/pages/LabelData/ObjectAnnotation/object';
import TaskObjectService from '../services/taskObjectsMarking';
import VideoService from '../services/videos';

export default {
  methods: {
    // Get & load image
    async getImageForObjectsMarking(page, size = 30) {
      const [error, data] = await TaskObjectService.getObjects({
        task: this.selectedTask,
        page: page,
        pageSize: size,
        ordering: 'id',
      });
      if (error || data.count === 0) {
        this.imageLoading = false;
        return null;
      }

      const imageToMark = data.results[0];
      const imageUrl = await this.loadImage(imageToMark);
      if (!imageUrl) {
        this.imageLoading = false;
        return imageToMark;
      }
      this.setTaskImageMarkingUrl(imageUrl);
      return imageToMark;
    },

    // Get & load image
    async getProjectorImageForObjectsMarking(page) {
      const [error, data] = await TaskObjectService.getProjectorObject({
        task: this.selectedTask,
        page: page,
      });
      if (error) {
        this.imageLoading = false;
        return;
      }
      const imageToMark = data.results[0];
      const imageUrl = await this.loadImage(imageToMark);
      if (!imageUrl) {
        this.imageLoading = false;
        return;
      }
      this.setTaskImageMarkingUrl(imageUrl);
    },
    createLabelForPolygonConfig(polygonPoints, name, id) {
      // let min_y = 480,
      let { width: min_x, height: min_y } = this.imageDimensions;
      //   min_x = 640;
      for (let i = 1; i < polygonPoints.length; i += 2) {
        min_y = Math.min(min_y, polygonPoints[i]);
        min_x = Math.min(min_x, polygonPoints[i - 1]);
      }

      const label = createNewTextConfig(
        id,
        name,
        min_x,
        min_y - 15,
        this.textColor
      );
      return label;
    },

    getPolygonsAnnotations() {
      const { lines, labels } = this.polygons;
      const { width, height } = this.imageDimensions;
      const polygonsList = [];
      for (const [id, polygon] of Object.entries(lines)) {
        const normPoints = normalizePolygonPoints(
          polygon.points,
          width,
          height
        );
        if (polygon.points.length && polygon.name) {
          polygonsList.push({
            points: normPoints,
            name: polygon.name,
            actual_id: polygon.actual_id,
            stroke: polygon.stroke,
            is_seg_track: polygon.is_seg_track,
          });
        }
      }
      console.log('polygon List ->', polygonsList);
      return polygonsList;
    },

    createPolygonsFromExistingObjects(existingObjects) {
      const { width, height } = this.imageDimensions;
      const newPolygonsGroups = {
        lines: {},
        labels: {},
      };
      if (!existingObjects && !existingObjects?.length) {
        return newPolygonsGroups;
      }
      existingObjects.map((o) => {
        let { points, name } = o;
        const id = uuid.v4();
        points.forEach(function (item, index) {
          if (index % 2) {
            points[index] = item * height;
          } else {
            points[index] = item * width;
          }
        });
        const label = this.createLabelForPolygonConfig(points, name, id);
        newPolygonsGroups.lines[id] = {
          points: points,
          id: id,
          name: name,
          actual_id: o.actual_id,
          is_seg_track: o.is_seg_track,
        };
        if (o.stroke) newPolygonsGroups.lines[id]['stroke'] = o.stroke;
        newPolygonsGroups.labels[id] = label;
      });
      return newPolygonsGroups;
    },
    async getImageUrlFromS3(imageObj) {
      if (!imageObj) return;
      const payload = {
        bucket_name: this.organization + '-training',
        object_path: imageObj.path_to_image,
      };
      const [error, data] = await VideoService.getPresignedUrl(payload);
      if (error) {
        this.toast.error('Error getting Image');
        this.imageLoading = false;
        return;
      }
      const { presigned_url } = data;
      return presigned_url;
    },

    loadImage(imageObj) {
      return new Promise(async (resolve, rejectF) => {
        const img = new window.Image();
        const image_url = await this.getImageUrlFromS3(imageObj);
        img.src = image_url;
        img.onload = () => {
          this.image = img;
          this.stageSize.width = img.naturalWidth;
          this.stageSize.height = img.naturalHeight;
          this.image.width = img.naturalWidth;
          this.image.height = img.naturalHeight;

          this.imageLoading = false;
          resolve(image_url);
        };
        img.onerror = () => {
          resolve(0);
        };
      });
    },

    //Model Groups when get
    createRectLabelGroup(object) {
      const { width, height } = this.stageSize;
      let rect = createNewRectangleConfig(object, width, height);
      const lbl = createNewTextConfig(
        object.id,
        object.name,
        rect.x,
        rect.y - 15,
        this.textColor
      );
      return { rectangle: rect, label: lbl };
    },

    createCircleLabelGroup(object) {
      const { width, height } = this.stageSize;
      if (!object.points)
        return {
          plane_points: [],
          label: '',
        };

      let planePoints = object.points.plane_points;
      const planeColor = object.points.plane_color;
      var circles = [];
      var circle_index = 0;
      planePoints.forEach((point) => {
        circles.push(
          createNewCircleConfig(
            point,
            width,
            height,
            planeColor,
            object.name + '-' + circle_index
          )
        );
        circle_index += 1;
      });

      return { plane_points: circles, label: '' };
    },

    createRectanglesFromExistingObjects(existingObjects) {
      const newGroups = {
        rectangles: {},
        labels: {},
      };
      if (!existingObjects && !existingObjects.length) return newGroups;
      existingObjects.map((o) => {
        const { rectangle, label } = this.createRectLabelGroup(o);
        newGroups.rectangles[rectangle.name] = { ...rectangle };
        newGroups.labels[rectangle.name] = { ...label };
      });
      return newGroups;
    },

    addCircleFromObjects(group, name, point, newCircleName) {
      const { width, height } = this.stageSize;
      const planeColor = group.circles[name][0].fill;
      let newCircleObj = createNewCircleConfig(
        point,
        width,
        height,
        planeColor,
        newCircleName,
        true
      );
      newCircleObj['stroke'] = '#ff0000';
      newCircleObj['strokeWidth'] = 2;
      group.circles[name].push(newCircleObj);

      return group;
    },

    // Object transformer
    updateTransformer() {
      const transformerNode = this.$refs?.transformer?.getNode();
      if (!transformerNode) return;
      const stage = transformerNode?.getStage();
      if (!stage) return;
      const selectedNode = stage.findOne('.' + this.selectedShapeName);
      if (selectedNode === transformerNode.node()) {
        return;
      }
      if (selectedNode) {
        transformerNode.nodes([selectedNode]);
      } else {
        transformerNode.nodes([]);
      }
    },

    handleTransform(e, updateSize = false) {
      // const node = e.target;
      if (this.isAllNodeSelected) {
        const selectedShapeNames = Object.keys(this.groups.rectangles);
        selectedShapeNames.forEach((shapeName) => {
          const { rectangles, labels } = this.groups;
          const textToUpdate = labels[shapeName];
          const rectToUpdate = rectangles[shapeName];
          // Update position
          rectToUpdate.x = rectToUpdate.x;
          rectToUpdate.y = rectToUpdate.y;

          if (updateSize) {
            this.updateShapeSizeTransformer(rectToUpdate, node);
          } else {
            // Update scale
            rectToUpdate.scaleX = node.scaleX;
            rectToUpdate.scaleY = node.scaleY;
          }

          // Update rotation
          rectToUpdate.rotation = node.rotation();

          // Update label position
          if (updateSize) {
            textToUpdate.y =
              this.getLabelVerticalCoordinate(rectToUpdate.name) ??
              this.getDefaultLabelCoordinate(rectToUpdate.startPointY);
          } else {
            textToUpdate.y = rectToUpdate.y - 15;
          }
          textToUpdate.x = rectToUpdate.x;
        });
      } else {
        const node = e.target;
        const { rectangles, labels } = this.groups;
        const textToUpdate = labels[this.selectedShapeName];
        const rectToUpdate = rectangles[this.selectedShapeName];
        rectToUpdate.x = node.x();
        rectToUpdate.y = node.y();
        if (updateSize) {
          this.updateShapeSizeTransformer(rectToUpdate, node);
        } else {
          rectToUpdate.scaleX = node.scaleX();
          rectToUpdate.scaleY = node.scaleY();
        }
        rectToUpdate.rotation = node.rotation();
        if (updateSize) {
          textToUpdate.y =
            this.getLabelVerticalCoordinate(rectToUpdate.name) ??
            this.getDefaultLabelCoordinate(rectToUpdate.startPointY);
        } else {
          textToUpdate.y = node.y() - 15;
        }
        textToUpdate.x = node.x();
        if (textToUpdate.y < 0) {
          textToUpdate.y = rectToUpdate.height + textToUpdate.y;
        }
      }
    },

    updateShapeSizeTransformer(rectToUpdate, node) {
      const newWidth = rectToUpdate.width * node.scaleX();
      const newHeight = rectToUpdate.height * node.scaleY();
      node.scaleX(1);
      node.scaleY(1);
      rectToUpdate.scaleX = node.scaleX();
      rectToUpdate.scaleY = node.scaleY();
      rectToUpdate.width = newWidth;
      rectToUpdate.height = newHeight;
    },

    getLabelVerticalCoordinate(key) {
      const { rectangles } = this.groups;
      const defaultSize = 15;
      const negativeAxis = -1;
      if (!rectangles[key]) return null;

      const defaultAxis =
        rectangles[key].y !== negativeAxis
          ? rectangles[key].y - this.labelVerticalGap
          : rectangles[key].startPointY - this.labelVerticalGap;

      let y =
        this.currentLabelSize > defaultSize
          ? defaultAxis - (this.currentLabelSize - defaultSize)
          : defaultAxis;
      if (y < 0) y += rectangles[key].height;
      return y;
    },

    async handleTransformCircle(e) {
      const node = e.target;
      const { circles, labels } = this.groups;
      const pointname = e.target.name();
      const planeName = pointname.split('-')[0];

      for (let i = 0; i < circles[planeName].length; i++) {
        if (circles[planeName][i].name == pointname) {
          circles[planeName][i].x = node.x();
          circles[planeName][i].y = node.y();
        }
      }

      await this.updateCirclePoints(pointname);
    },

    // Model objects before SAVE
    getAnnotations() {
      const tempAnnotationsGroups = deepClone(this.groups);
      const { rectangles, labels } = tempAnnotationsGroups;
      let annotationList = [];
      for (const [name, rectangle] of Object.entries(rectangles)) {
        const serializedRectangle = this.serializeNodeToObject(name);
        let annotation = {
          ...rectangle,
          ...serializedRectangle,
          name: labels[name].text,
        };

        delete annotation['freeDrawing'];
        annotationList.push(annotation);
      }
      return annotationList.map(OBJECT.filterRectanglePropsFromObject);
    },

    checkOutofBounds(position, action, node, value) {
      action === 'vertical' || action === 'up' || action === 'down'
        ? (position.y += value)
        : (position.x += value);
      const isOut =
        position.x <= 0 ||
        position.y <= 0 ||
        position.x + node.width() >= 640 ||
        position.y + node.height() >= 480;

      if (isOut) {
        return true;
      }
    },

    findNodeByName(name) {
      const stage = this.$refs.stage.getStage();
      return stage.findOne('.' + name);
    },

    getRotatedRectangleVertices(topLeftX, topLeftY, rotation, width, height) {
      const cos = Math.cos(rotation);
      const sin = Math.sin(rotation);
      const rotationMatrix = [
        [cos, -sin],
        [sin, cos],
      ];

      const relativePositions = [
        [width, 0],
        [width, height],
        [0, height],
      ];

      const rotatedPositions = relativePositions.map((position) => {
        const x =
          position[0] * rotationMatrix[0][0] +
          position[1] * rotationMatrix[0][1];
        const y =
          position[0] * rotationMatrix[1][0] +
          position[1] * rotationMatrix[1][1];
        return [x, y];
      });

      const vertices = rotatedPositions.map((position) => {
        return [position[0] + topLeftX, position[1] + topLeftY];
      });

      return {
        topLeft: { x: topLeftX, y: topLeftY },
        topRight: { x: vertices[0][0], y: vertices[0][1] },
        bottomRight: { x: vertices[1][0], y: vertices[1][1] },
        bottomLeft: { x: vertices[2][0], y: vertices[2][1] },
      };
    },

    serializeNodeToObject(
      nodeName,
      isAnnotation = false,
      rotateEnabled = false
    ) {
      const { width, height } = this.imageDimensions;
      const dimensionInJson = this.calculateNodeCoordinates(
        nodeName,
        isAnnotation,
        rotateEnabled
      );
      normalizeDimension(dimensionInJson, width, height);
      return dimensionInJson;
    },
    checkOutofBoundsExpansion(position, action, node, value) {
      let isOut = 0;
      if (action === 'right') {
        position.x += value;
        isOut = position.x + node.width() >= 640;
      }
      if (action === 'left') {
        position.x -= value;
        isOut = position.x <= 0;
      }
      if (action === 'down') {
        position.y += value;
        isOut = position.y + node.height() >= 480;
      }
      if (action === 'up') {
        position.y -= value;
        isOut = position.y <= 0;
      }
      if (isOut) {
        return true;
      }
    },
    handleNodeButtonEvents(nodeName, action, value) {
      const node = this.findNodeByName(nodeName);
      const heightCheck = parseInt(node.height() + value);
      const widthCheck = parseInt(node.width() + value);
      if (heightCheck < 0) {
        return false;
      }
      if (widthCheck < 0) {
        return false;
      }
      const position = node.getAbsolutePosition();
      if (
        this.checkOutofBoundsExpansion(position, action, node, value) &&
        value > 0
      ) {
        return false;
      }
      if (action === 'right') {
        node.width(node.width() + value);
      } else if (action === 'down') {
        node.height(node.height() + value);
      } else if (action === 'up') {
        node.y(node.y() - value);
        node.height(node.height() + value);
      } else if (action === 'left') {
        node.x(node.x() - value);
        node.width(node.width() + value);
      }
      this.handleTransform({ target: node });
      return true;
    },
    handleNodeMoveButtonEvents(nodeName, action, value) {
      const node = this.findNodeByName(nodeName);
      const position = node.getAbsolutePosition();
      if (this.checkOutofBounds(position, action, node, value)) {
        return false;
      }

      if (action === 'vertical') {
        node.y(node.y() + value);
      } else if (action === 'horizontal') {
        node.x(node.x() + value);
      }
      this.handleTransform({ target: node });
      return true;
    },
    calculateNodeCoordinates(
      nodeName,
      isAnnotation = false,
      rotateEnabled = false
    ) {
      const node = this.findNodeByName(nodeName);
      let position = node.getAbsolutePosition();
      if (isAnnotation && node.hasOwnProperty('attrs')) {
        position.x = node.attrs?.x;
        position.y = node.attrs?.y;
      }
      const ht = node.height();
      const wt = node.width();
      const sc = node.scale();
      const rotation = node.rotation();
      const rectWidth = sc.x * wt;
      const rectHeight = sc.y * ht;
      const midpoints = calculateRectangleMidPoint(
        position.x,
        position.y,
        rectWidth,
        rectHeight
      );
      if (rotateEnabled) {
        const corners = this.getRotatedRectangleVertices(
          position.x,
          position.y,
          rotation * (Math.PI / 180),
          wt,
          ht
        );
        position.x2 = corners.topRight.x;
        position.y2 = corners.topRight.y;
        position.x3 = corners.bottomRight.x;
        position.y3 = corners.bottomRight.y;
        position.x4 = corners.bottomLeft.x;
        position.y4 = corners.bottomLeft.y;
        midpoints.midx = (position.x + position.x3) / 2;
        midpoints.midy = (position.y + position.y3) / 2;
      }
      const returnObj = {
        height: ht * sc.y,
        width: wt * sc.x,
        rotation: rotation,
        ...position,
        ...midpoints,
      };
      return returnObj;
    },
  },
};
