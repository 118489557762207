<template>
  <a-modal
    id="ai-annotation-modal"
    centered
    destroy-on-close
    style="width: 40%"
    :ok-text="step === 1 ? 'Next' : step === 2 ? 'Next' : 'AI Annotate'"
    :visible="showAiAnnotation"
    title="Object Model Type"
    :mask-closable="false"
    @ok="handleOk"
    @cancel="handleAiAnnotationCancel"
  >
    <a-space size="40" direction="vertical" class="w-100">
      <template v-if="step === 1">
        <a-card title="Model Type" size="small" hoverable>
          <a-select
            v-model:value="selectedModelTypeObject"
            class="w-100"
            :options="modelTypeSelection"
            mode="multiple"
          />
        </a-card>
      </template>

      <template v-if="step === 2">
        <a-typography-title :level="4" class="ml-2 mt-2">
          {{ modelChoice ? modelChoice : '' }}
        </a-typography-title>
        <a-table
          class="mt-3"
          :columns="ModelVersionAiAnnotationColumns"
          :data-source="sortedModelVersionList"
          :row-selection="rowSelection"
          :row-key="'id'"
          :scroll="{
            y: '40vh',
            x: true,
          }"
          :pagination="false"
        >
          <template #emptyText>
            <div
              id="no-models-found"
              style="height: 35vh"
              class="d-flex flex-column align-items-center justify-content-center"
            >
              <laptop-outlined style="font-size: 40px" />
              <span class="mt-2">No Models Found</span>
            </div>
          </template>
          <template #bodyCell="{ column, text, record, index }">
            <template v-if="column.dataIndex === 'description'">
              {{ text }}
            </template>

            <template v-if="column.dataIndex === 'date'">
              {{ text ? dateHelper.formatDate(text) : '-' }}
            </template>

            <template v-if="column.dataIndex === 'version'">
              {{ text }}
            </template>
          </template>
        </a-table>
      </template>

      <template v-if="step === 3">
        <a-typography-title :level="4" class="ml-2 mt-2">
          Task Objects List
        </a-typography-title>
        <a-table
          class="mt-3"
          :data-source="taskObjects"
          :row-selection="{
            type: 'checkbox',
            selectedRowKeys: selectedObjects,
            onChange: onObjectSelectionChange,
          }"
          :columns="taskObjectListColumns"
          :row-key="'id'"
          :scroll="{
            y: '40vh',
            x: true,
          }"
          :pagination="false"
        >
          <template #emptyText>
            <div
              id="no-task-objects-found"
              style="height: 35vh"
              class="d-flex flex-column align-items-center justify-content-center"
            >
              <laptop-outlined style="font-size: 40px" />
              <span class="mt-2">No Task Objects Found</span>
            </div>
          </template>
        </a-table>
      </template>
    </a-space>
    <template #footer>
      <div class="d-flex justify-content-end">
        <a-button class="mr-2" @click="handleAiAnnotationCancel"
          >Cancel</a-button
        >
        <a-button v-if="step > 1" class="mr-2" @click="handleBack"
          >Back</a-button
        >
        <a-button
          type="primary"
          @click="handleOk"
          :disabled="isProceedButtonDisabled"
        >
          {{ getButtonText }}
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { LaptopOutlined } from '@ant-design/icons-vue';
import dayjs from 'dayjs';
import dateHelper from 'src/components/shared/Helpers/dateHelper';
import LongRunningTaskService from 'src/services/longRunningTask';
import ModelTypeService from 'src/services/modelTypes';
import SQSServices from 'src/services/sqs';
import { mapGetters } from 'vuex';
import {
  ModelVersionAiAnnotationColumns,
  modelTypeSelection,
  taskObjectListColumns,
} from './config';

export default {
  components: {
    LaptopOutlined,
  },

  inject: ['toast'],

  props: {
    showAiAnnotation: { type: Boolean, default: false, required: true },
    selectedImages: { type: Array, default: () => {}, required: true },
    taskObjectsList: { type: Array, default: () => {}, required: true },
  },

  emits: ['setShowAiAnnotation'],

  setup() {
    return {
      ModelVersionAiAnnotationColumns,
      taskObjectListColumns,
      dateHelper,
      modelTypeSelection,
    };
  },

  data() {
    return {
      step: 1,
      selectedRow: null,
      selectedModelTypeObject: [],
      modelChoiceId: null,
      selectedObjects: [],
      selectObjectsName: [],
      taskObjects: [],
      TaskObjectColumns: [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
      ],
    };
  },

  computed: {
    ...mapGetters([
      'modelVersionsList',
      'taskName',
      'selectedTask',
      'organization',
      'modelChoice',
    ]),

    sortedModelVersionList() {
      if (!this.modelVersionsList.length) return [];
      const sorted = this.modelVersionsList
        .filter((mv) => !mv.is_deleted && mv.model_type === this.modelChoiceId)
        .sort((a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1));
      return sorted;
    },

    rowSelection() {
      return {
        type: 'radio',
        selectedRowKeys: [this.selectedRow],
        onChange: this.onRowSelect,
      };
    },

    getButtonText() {
      const doesNotContainObject =
        !this.selectedModelTypeObject.includes('objects');
      if (doesNotContainObject) {
        return 'AI Annotate';
      }
      return this.step === 1
        ? 'Next'
        : this.step === 2
        ? 'Next'
        : 'AI Annotate';
    },

    isProceedButtonDisabled() {
      if (this.step === 1 && this.selectedModelTypeObject.length === 0) {
        return true;
      }
      if (this.step === 2 && !this.selectedRow) {
        return true;
      }
      if (this.step === 3 && this.selectedObjects.length === 0) {
        return true;
      }
      return false;
    },
  },

  watch: {
    async showAiAnnotation(value) {
      if (value && !this.modelChoiceId) await this.setModelTypeId();
    },
    taskObjectsList(value) {
      this.taskObjects = value.filter(
        (obj) =>
          !obj.is_static && !obj.next_step_trigger && !obj.previous_step_trigger
      );
    },
  },

  methods: {
    async setModelTypeId() {
      if (!this.modelChoice) return;
      const [error, data] = await ModelTypeService.fetchModelTypes();
      if (error || !data?.length) return;
      const modelChoiceObj = data.find((obj) => obj.name === this.modelChoice);
      if (!modelChoiceObj) return;
      this.modelChoiceId = modelChoiceObj.id;
    },

    handleOk() {
      const ContainObject = this.selectedModelTypeObject.includes('objects');
      if (ContainObject) {
        if (this.step === 1) {
          this.proceedToObjectSelection();
        } else if (this.step === 2) {
          this.proceedToTaskObjectsSelection();
        } else {
          this.startAiAnnotationProcess();
        }
      } else {
        this.startAiAnnotationProcess();
      }
    },
    handleBack() {
      if (this.step > 1) {
        this.step -= 1; // Navigate back one step
      }
    },
    proceedToObjectSelection() {
      if (this.selectedModelTypeObject.length !== 0) {
        this.step = 2;
      } else {
        this.$message.error('Please select a model type!');
      }
    },
    proceedToTaskObjectsSelection() {
      if (this.selectedRow) {
        this.step = 3;
      } else {
        this.$message.error('Please select at least one model version!');
      }
    },
    onObjectSelectionChange(selectedRowKeys) {
      this.selectedObjects = selectedRowKeys;
      const selectedObjectNames = this.taskObjects
        .filter((obj) => selectedRowKeys.includes(obj.id))
        .map((obj) => obj.name);
      this.selectObjectsName = selectedObjectNames;
    },

    getSelectedImageKeys() {
      if (!this.selectedImages) return [];
      let keys = [];
      for (let key of Object.keys(this.selectedImages)) {
        if (this.selectedImages[key]) keys.push(Number(key));
      }
      return keys;
    },

    async startAiAnnotationProcess() {
      const ContainObject = this.selectedModelTypeObject.includes('objects');
      if (ContainObject) {
        if (!this.sortedModelVersionList.length)
          return this.toast.info('No model version exist.');
        if (!this.selectedRow)
          return this.toast.info('Please select any model version.');
      }
      for (const objectType of this.selectedModelTypeObject) {
        console.log('objectType', objectType);
        const longRunningId = await this.getLongRunningTaskId(objectType);

        if (!longRunningId)
          return this.toast.error(
            `Failed to start AI annotation process for ${objectType} object.`
          );
        const response = await this.sendMessageToQueue(longRunningId);
        if (response)
          this.toast.success(
            `AI annotation process started successfully for ${objectType} object.`
          );
        else
          this.toast.error(
            `Failed to start AI annotation process ${objectType} object.`
          );
      }
      this.handleAiAnnotationCancel();
    },

    async getLongRunningTaskId(objectType) {
      let model_s3_key = null;
      let versionNumber = null;
      let modelVersionId = null;
      if (objectType === 'hands') {
        model_s3_key = objectType;
      } else {
        const modelVersion = this.modelVersionsList.find(
          (mv) => mv.id == this.selectedRow
        );
        model_s3_key = modelVersion.model_s3_key;
        versionNumber = modelVersion.version_number;
        modelVersionId = modelVersion.id;
      }
      const payload = {
        name: 'auto annotation',
        status: 'pending',
        details: {
          model_s3_key: model_s3_key,
          annotation_ids: this.getSelectedImageKeys(),
          objects: this.selectObjectsName,
        },
        event: {
          name: 'AI Annotation',
          model_version: versionNumber,
          model_version_id: modelVersionId,
        },
        organization: this.organization,
        task: this.selectedTask,
      };

      const [error, data] = await LongRunningTaskService.createLongRunningTasks(
        payload
      );
      if (error) return null;
      return data.id;
    },

    async sendMessageToQueue(longRunningTaskId) {
      const payload = {
        queueName: 'object-detection',
        sqsMessage: {
          task_id: this.selectedTask,
          name: this.taskName,
          organization: this.organization,
          message_type: 'auto_annotation',
          long_running_task_id: longRunningTaskId,
        },
      };

      const [error] = await SQSServices.sendSQSMessage(payload);
      if (error) {
        return false;
      }
      return true;
    },

    onRowSelect(selectedRowKeys) {
      this.selectedRow = selectedRowKeys[0];
    },

    handleAiAnnotationCancel() {
      this.selectedRow = null;
      this.selectedModelTypeObject = [];
      this.selectedObjects = [];
      this.step = 1;
      this.$emit('setShowAiAnnotation', false);
    },
  },
};
</script>
