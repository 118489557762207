<template>
  <a-modal
    class="opc-add-tag-modal"
    :visible="visible"
    title="Add Tag Information"
    @cancel="onCancel"
  >
    <template #footer>
      <a-button @click="onCancel">Cancel</a-button>
      <a-button type="primary" :loading="isLoading" @click="updateTag"
        >Update</a-button
      >
    </template>
    <a-tabs
      v-model:activeKey="currentTab"
      type="card"
      class="w-100"
      size="small"
    >
      <a-tab-pane key="1" tab="Tag Parameters">
        <tag-parameters-tab
          :tag-address-tree="tagAddressTree"
          :selectedTag="selectedTag"
          @close="tagModalVisible = false"
        ></tag-parameters-tab>
      </a-tab-pane>
      <a-tab-pane key="2" tab="Task Parameters">
        <task-parameters-tab
          :subStepOptions="subStepOptions"
          :selectedTag="selectedTag"
          @close="taskModalVisible = false"
        ></task-parameters-tab>
      </a-tab-pane>
    </a-tabs>
  </a-modal>
</template>

<script>
import IoTService from '../../../../services/iot';
import validateTagData from 'src/utils/iot';
import { mapActions, mapGetters } from 'vuex';
import TagParametersTab from './TagParametersTab.vue';
import TaskParametersTab from './TaskParametersTab.vue';
import getTaskDetails from 'src/components/shared/Helpers/getTaskDetails';

export default {
  inject: ['toast'],
  props: ['visible', 'selectedTag', 'tagAddressTree'],
  emits: ['close', 'refresh-tag-list'],
  components: {
    TagParametersTab,
    TaskParametersTab,
  },

  data() {
    return {
      processes: [],
      subStepOptions: [],
      currentTab: '1',
      isLoading: false,
    };
  },

  watch: {
    visible(val) {
      if (val) {
        this.fetchTaskDetails(this.selectedTag.task);
      } else {
        this.clearTaskDetails();
      }
    },

    'selectedTag.task': function(newVal) {
      this.fetchTaskDetails(newVal);
      if (this.selectedTag.triggerParams) {
        this.selectedTag.triggerParams.step_idx = null;
      }
      console.log(this.selectedTag);
    },

    taskDetails(value) {
      if (!value) return;
      this.setTaskNameAndProcess(value);
      getTaskDetails.apply(this, [this.taskDetails]);
    },

    processes(value) {
      let index = 0;
      this.subStepOptions = [];
      value.map((p) => {
        p.steps.map((s) => {
          s.substeps.map((ss) => {
            this.subStepOptions.push({
              label: ss,
              value: index,
            });
            index += 1;
          });
        });
      });
    },
  },

  computed: {
    ...mapGetters([
      'isFetchingTasks',
      'isFetchingDevices',
      'taskDetails',
      'taskProcesses',
      'taskName',
    ]),
  },

  methods: {
    ...mapActions([
      'fetchTaskDetails',
      'setTaskNameAndProcess',
      'clearTaskDetails',
    ]),
    async updateTag() {
      if (validateTagData(this.selectedTag)) {
        this.isLoading = true;
        if (!this.selectedTag.triggerOn) {
          this.selectedTag.triggerOn = null;
          this.selectedTag.triggerParams = {};
        }

        const [error, _] = await IoTService.updateTag(
          this.selectedTag.id,
          this.selectedTag
        );

        if (error) {
          this.isLoading = false;
          return this.toast.error('Failed to update OPC Tag.', {
            timeout: 3000,
          });
        }

        await this.$emit('refresh-tag-list');

        this.isLoading = false;
        this.$emit('close');
      } else {
        this.toast.error('Please fill all the required fields.', {
          timeout: 3000,
        });
      }
    },

    onCancel() {
      this.currentTab = '1';
      console.log('cancelled called');
      this.$emit('close');
    },
  },
};
</script>

<style>
.opc-add-tag-modal .ant-form-item {
  width: 95%;
  margin-bottom: 10px;
}

.opc-add-tag-modal .ant-card {
  margin-bottom: 1rem;
}

.opc-add-tag-modal .ant-modal-footer {
  display: block;
}
</style>
