<template>
  <div class="py-1 px-4">
    <AddTag :device="device" @refresh="refreshTags" />
  </div>
  <div class="py-4 px-2 p-md-4">
    <div class="opc-tag-table">
      <a-table
        class="table-container"
        :columns="columns"
        :data-source="tags"
        :pagination="false"
        :loading="isTagsLoading"
        :scroll="{ x: 'max-content', y: 410 }"
      >
        <template #bodyCell="{ record, column, index }">
          <!-- TagId Column -->
          <div v-if="column.dataIndex === 'tag_id'" class="tagId-container">
            <a-input
              v-if="previousData[record.id]"
              v-model:value="record.tag_name"
              style="margin: -5px 0; width: 250px"
              :disabled="isTagUpdating[record.id]"
            />
            <span v-else>
              <a-tooltip :title="record.tag_id">
                <div class="ellipsis-text">
                  {{ record.tag_name }}
                </div></a-tooltip
              >
            </span>
          </div>

          <!-- Editable Column -->
          <div class="text-center" v-if="column.dataIndex === 'editable'">
            <span>
              <a-tag v-if="record.editable === false" color="blue">read</a-tag>
              <a-tag v-else color="green">write</a-tag>
            </span>
          </div>
          <!-- Action Column -->
          <div v-if="column.dataIndex === 'action'">
            <span class="d-flex ml-auto mt-4">
              <!-- Select Input -->
              <a-form-item name="select">
                <a-select
                  placeholder="Please select a trigger"
                  style="width: 260px"
                  mode="multiple"
                  :max-tag-count="1"
                  v-model:value="record.trigger_type"
                  :options="triggerChoices"
                  :disabled="
                    isTriggerDisabled[record.id] || isTagUpdating[record.id]
                  "
                />
              </a-form-item>

              <!-- Edit -->
              <div class="editable-row-operations">
                <span v-if="previousData[record.id]">
                  <a-space>
                    <a-button
                      type="primary"
                      :loading="isTagUpdating[record.id]"
                      @click="handleSave(record)"
                      ><template #icon> <check-outlined /></template
                    ></a-button>
                    <a-button
                      type="danger"
                      :disabled="isTagUpdating[record.id]"
                      @click="handleCancel(record)"
                    >
                      <template #icon> <close-outlined /></template>
                    </a-button>
                  </a-space>
                </span>
                <span v-else>
                  <a-button id="edit-task-btn" @click="handleEdit(record)">
                    <template #icon>
                      <form-outlined class="text-light" /> </template
                  ></a-button>
                </span>
              </div>

              <!-- Delete -->
              <a-popconfirm
                :disabled="!isTriggerDisabled[record.id]"
                title="Are you sure delete this tag?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="deleteTag(record.id)"
              >
                <a-button
                  type="danger"
                  class="ml-2"
                  :disabled="!isTriggerDisabled[record.id]"
                >
                  <template #icon>
                    <delete-outlined />
                  </template>
                </a-button>
              </a-popconfirm>
            </span>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import {
  DeleteOutlined,
  FormOutlined,
  CheckOutlined,
  CloseOutlined
} from '@ant-design/icons-vue';
import AddTag from './AddTag.vue';
import { OpcTagsColumn, OpcTriggers } from './config';
import IoTService from 'src/services/iot';

export default {
  components: {
    AddTag,
    FormOutlined,
    DeleteOutlined,
    CheckOutlined,
    CloseOutlined
  },
  inject: ['toast'],
  props: ['selectedTask', 'device'],

  data() {
    return {
      columns: OpcTagsColumn,
      triggerChoices: OpcTriggers,
      tags: [],
      previousData: {},
      isTriggerDisabled: {},
      isTagUpdating: {},
      isTagsLoading: false,
      toastTimeoutDuration: 3000,
      errorMessage: 'An unexpected error has occured. Please refresh the page.'
    };
  },

  created() {
    this.getTagList();
  },

  methods: {
    handleEdit(record) {
      this.previousData[record.id] = {};
      this.previousData[record.id].tag_name = record.tag_name;
      this.previousData[record.id].trigger_type = [...record.trigger_type];
      this.toggleTriggerDisabled(record.id, false);
    },

    handleCancel(record) {
      this.resetFormToPreviousState(record);
      this.removePreviousData(record);
    },

    resetFormToPreviousState(record) {
      record.tag_name = this.previousData[record.id].tag_name;
      record.trigger_type = [...this.previousData[record.id].trigger_type];
    },

    removePreviousData(record) {
      this.toggleTriggerDisabled(record.id, true);
      delete this.previousData[record.id];
    },

    addTriggerState(payload) {
      const previousTriggers = this.previousData[payload.id].trigger_type;
      const currentTriggers = payload.trigger_type;

      const addTriggers = currentTriggers.filter(
        trigger => !previousTriggers.includes(trigger)
      );
      const deleteTriggers = previousTriggers.filter(
        trigger => !currentTriggers.includes(trigger)
      );

      payload.addTriggers = addTriggers;
      payload.deleteTriggers = deleteTriggers;
    },

    async handleSave(record) {
      try {
        this.isTagUpdating[record.id] = true;
        const payload = JSON.parse(JSON.stringify(record));
        this.addTriggerState(payload);
        const [error, _] = await IoTService.updateTag(record.id, payload);
        if (error) {
          this.handleCancel(record);
          this.isTagUpdating[record.id] = false;
          return this.toast.error('Failed to update OPC Tag.', {
            timeout: this.toastTimeoutDuration
          });
        }
        this.removePreviousData(record);
        this.isTagUpdating[record.id] = false;
        this.toast.success('OPC Tag updated successfully.', {
          timeout: this.toastTimeoutDuration
        });
      } catch (error) {
        this.handleCancel(record);
        this.isTagUpdating[record.id] = false;
        return this.toast.error(this.errorMessage);
      }
    },

    refreshTags(data) {
      this.tags.push(data);
      this.toggleTriggerDisabled(data.id, true);
    },

    toggleTriggerDisabled(id, toggle) {
      this.isTriggerDisabled[id] = toggle;
    },

    isDeviceOrTaskEmpty() {
      return (
        this.selectedTask === null ||
        this.selectedTask === undefined ||
        this.device?.Device === null ||
        this.device?.Device === undefined
      );
    },

    async getTagList() {
      try {
        if (this.isDeviceOrTaskEmpty()) {
          this.isTagsLoading = false;
          return this.toast.error('Unable to fetch OPC Tags.', {
            timeout: this.toast.toastTimeoutDuration
          });
        }
        const deviceId =
          typeof this.device.Device === 'object'
            ? this.device.Device.id
            : this.device.Device;
        this.isTagsLoading = true;
        const [error, data] = await IoTService.getOPCTagsByTaskAndDevice(
          this.selectedTask,
          deviceId
        );
        if (error) {
          this.isTagsLoading = false;
          return this.toast.error('Failed to reterive OPC Tags.', {
            timeout: this.toastTimeoutDuration
          });
        }
        this.isTagsLoading = false;
        this.tags = data;
        this.disableTagEdit();
      } catch (error) {
        this.isTagsLoading = false;
        return this.toast.error(this.errorMessage);
      }
    },

    disableTagEdit() {
      this.tags.forEach(tag => {
        this.toggleTriggerDisabled(tag.id, true);
      });
    },

    async deleteTag(tagId) {
      try {
        this.isTagsLoading = true;
        const [error, data] = await IoTService.deleteOPCTag(tagId);
        if (error) {
          this.isTagsLoading = false;
          return this.toast.error('Failed to delete tag.', {
            timeout: this.toastTimeoutDuration
          });
        }
        this.tags = this.tags.filter(tag => tag.id !== tagId);
        delete this.isTriggerDisabled[tagId];
        delete this.isTagUpdating[tagId];
        this.isTagsLoading = false;
        this.toast.success('OPC Tag deleted successfully.');
      } catch (error) {
        this.isTagsLoading = false;
        return this.toast.error(this.errorMessage);
      }
    }
  }
};
</script>

<style>
/* Add your styles here */
.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tagId-container {
  width: 85%;
}
.add-tag {
  float: right;
}
.tag-button-container {
  height: 0.5rem;
  margin-top: 2%;
  width: 100%;
}
.editable-row-operations {
  margin-left: 2%;
}
.opc-tag-table .ant-table-row > .ant-table-cell {
  padding: 0px 16px !important;
}
#edit-task-btn.ant-btn {
  background: #fbe460;
  border-color: #fbe460;
}
#edit-task-btn.ant-btn:hover {
  background: #ffc107;
  border-color: #ffc107;
}
</style>
