<template>
  <div class="p-1">
    <a-card
      v-for="{ key, paramType, title } in requiredStepsForCycle"
      :key="key"
      :title="title"
      :loading="isUpdatingTaskParams"
      size="small"
      hoverable
    >
      <div
        v-for="(process, processIndex) in taskProcesses"
        :key="process.name"
        class="d-flex my-1"
      >
        <a-typography class="w-25 text-center">
          Process: <strong>{{ process.name }}</strong>
        </a-typography>

        <a-select
          :value="taskParameters[paramType][key][processIndex] || []"
          :options="getStepOptions(processIndex)"
          class="w-75"
          mode="multiple"
          @change="(value) => setParamValue(key, value, processIndex)"
        />
      </div>
    </a-card>

    <!--  -->
    <a-card
      v-for="{ key, paramType, title } in cycleParams"
      :key="key"
      :title="title"
      :loading="isUpdatingTaskParams"
      size="small"
      hoverable
    >
      <a-input
        :value="taskParameters[paramType][key]"
        :name="key"
        @change="handleNumberChange"
      />
    </a-card>
    <!--  -->

    <sub-step-times :processes="taskProcesses" />
    <a-card
      title="Temporal Smoothness"
      :loading="isUpdatingTaskParams"
      size="small"
      hoverable
    >
      <a-input
        v-model:value="taskParameters['telemetryParams']['temporal_smoothness']"
      />
    </a-card>
    <a-card
      title="Add Background Time to Step Time"
      :loading="isUpdatingTaskParams"
      size="small"
      hoverable
    >
      <div class="custom-control custom-switch">
        <input
          :id="3"
          type="checkbox"
          class="custom-control-input"
          v-bind:class="{
            active:
              taskParameters['telemetryParams'][
                'add_background_time_to_step_time'
              ],
          }"
          v-model="
            taskParameters['telemetryParams'][
              'add_background_time_to_step_time'
            ]
          "
          @change="
            () =>
              setParamValue(
                'add_background_time_to_step_time',
                taskParameters['telemetryParams'][
                  'add_background_time_to_step_time'
                ]
              )
          "
        />
        <label
          class="custom-control-label"
          :class="{
            'text-secondary':
              !taskParameters['telemetryParams'][
                'add_background_time_to_step_time'
              ],
            'text-dark':
              taskParameters['telemetryParams'][
                'add_background_time_to_step_time'
              ],
          }"
          :for="3"
        >
        </label>
      </div>
    </a-card>
    <a-card
      title="Object Rotation"
      :loading="isUpdatingTaskParams"
      size="small"
      hoverable
    >
      <a-switch
        :checked="state.objectRotation"
        @change="handleObjectRotationChange"
      />
    </a-card>
  </div>
</template>
<script>
import { deepClone } from 'src/utils/task';
import { mapActions, mapGetters } from 'vuex';
import { telemetryParams } from './config';
import SubStepTimes from './SubStepTimes.vue';
export default {
  components: {
    SubStepTimes,
  },
  props: ['state'],
  emits: ['updateState'],
  setup() {
    const { requiredStepsForCycle, cycleParams } = telemetryParams;
    return { requiredStepsForCycle, cycleParams };
  },
  data() {
    return {
      substepList: [],
      processStepsOptions: {},
      isBackgroundTimetoStepTimeEnabled: true,
    };
  },

  watch: {
    async isAddBackgroundTimeToStepTimeEnabled(newValue) {
      this.updateBackgroundTimeAttributionToStepTime();
    },
  },

  computed: {
    ...mapGetters([
      'taskProcesses',
      'taskParameters',
      'selectedTask',
      'allTasks',
      'isAddBackgroundTimeToStepTimeEnabled',
      'isUpdatingTaskParams',
      'isParentOfVirtualTasks',
      'stepsToIndexMapping',
    ]),
  },

  beforeMount() {
    console.log('mounted', this.taskProcesses);
    this.substepList = this.taskProcesses.map((process) => {
      const allSubSteps = process.steps.reduce(
        (res, step) => [...res, ...step.substeps],
        []
      );

      return Object.keys(allSubSteps);
    });
  },

  methods: {
    ...mapActions([
      'setTaskParameters',
      'setTasks',
      'updateTaskFields',
      'setIsAddBackgroundTimeToStepTimeEnabled',
    ]),

    handleObjectRotationChange(value) {
      const state = {
        objectRotation: value,
      };
      this.$emit('updateState', state);
    },

    enableAddBackgroundTimetoStepTime() {
      this.setIsAddBackgroundTimeToStepTimeEnabled(
        !this.isAddBackgroundTimeToStepTimeEnabled
      );
    },

    getStepOptions(processIndex) {
      if (this.processStepsOptions[processIndex]) {
        return this.processStepsOptions[processIndex];
      }
      const options = Object.entries(this.stepsToIndexMapping).map(
        ([label, value]) => ({
          label: label,
          value: value,
        })
      );
      this.processStepsOptions = {
        ...this.processStepsOptions,
        [processIndex]: options,
      };
      return options;
    },

    async updateBackgroundTimeAttributionToStepTime() {
      try {
        await this.updateTaskFields({
          add_background_time_to_step_time:
            this.isAddBackgroundTimeToStepTimeEnabled,
        });
      } catch (error) {
        console.log(
          'error occured while updating background time attribution to step time:',
          error
        );
      }
      const tempTasks = [...this.allTasks];
      const taskIndex = tempTasks.findIndex((t) => t.id === this.selectedTask);

      tempTasks[taskIndex]['add_background_time_to_step_time'] =
        this.isAddBackgroundTimeToStepTimeEnabled;
      this.setTasks(tempTasks);
    },

    setParamValue(paramKey, value, processIndex = null) {
      const tempTaskParams = deepClone(this.taskParameters);
      if (processIndex !== null && processIndex >= 0) {
        tempTaskParams['telemetryParams'][paramKey][processIndex] = value;
      } else {
        tempTaskParams['telemetryParams'][paramKey] = value;
      }
      this.setTaskParameters(tempTaskParams);
    },

    handleNumberChange(e) {
      const { value, name } = e.target;
      if (Number.isNaN(Number(value))) return;
      this.setParamValue(name, value);
    },
  },
};
</script>
<style>
.ant-card {
  margin: 0.5em 0;
}
</style>
