<template>
  <a-modal
    id="add-translation-modal"
    :visible="isTranslation"
    centered
    destroy-on-close
    :closable="false"
    :footer="null"
    :title="
      (Object.keys(similarSteps).length === 0 ? 'Add' : 'Update') +
      ' Translation'
    "
    width="60vw"
    @cancel="cancelTranslation"
  >
    <AddTranslation />
  </a-modal>

  <a-modal
    id="similar-steps-modal"
    :visible="showSimilarStepsModal"
    centered
    destroy-on-close
    :closable="false"
    :footer="null"
    :title="(!isTranslationEdit ? 'Add' : 'Update') + ' Similar Steps'"
    width="60vw"
  >
    <SimilarSteps />
  </a-modal>

  <a-modal
    id="projector-region-modal"
    :visible="showProjectorRegionModal"
    centered
    destroy-on-close
    :closable="true"
    :footer="null"
    :title="'IoT Plugins'"
    width="60vw"
    @cancel="closeProjectorModal"
  >
    <Devices />
  </a-modal>

  <a-modal
    id="step-time-modal"
    :visible="showStepTimeModal"
    centered
    destroy-on-close
    :closable="false"
    :footer="null"
    :title="`${isStepTimeExist ? 'Update' : 'Add'} Standard Time`"
    width="60vw"
  >
    <StepTime />
  </a-modal>

  <a-modal
    id="task-parameters-modal"
    :visible="showTaskParamsModal"
    centered
    width="50vw"
    destroy-on-close
    :closable="false"
    title="Task Parameters"
    :footer="null"
  >
    <TaskParameters />
  </a-modal>

  <a-modal
    id="mark-static-objects-modal"
    v-model:visible="showRegionDefinitionModal"
    centered
    width="80vw"
    destroy-on-close
    title="Define Regions / Static Objects"
    :closable="false"
    :footer="null"
  >
    <RegionDefinition />
  </a-modal>

  <a-modal
    id="upload-media-modal"
    v-model:visible="showUploadMediaModal"
    centered
    width="60vw"
    destroy-on-close
    title="Upload Step Media"
    :closable="false"
    :footer="null"
  >
    <UploadStepMedia />
  </a-modal>

  <!-- <a-modal
    v-model:visible="showAnnotationObjectsModal"
    centered
    width="60vw"
    :closable="false"
    destroy-on-close
    title="Add Annotation Object(s)"
    :footer="null"
  >
    <ObjectAnnotation />
  </a-modal>  -->

  <a-modal
    id="model-versions-modal"
    v-model:visible="showModelVersionsModal"
    centered
    width="60vw"
    destroy-on-close
    title="Model Versions"
    :footer="null"
    @cancel="
      showModal({
        modalType: types.SHOW_MODEL_VERSIONS_MODAL,
        value: false,
      })
    "
  >
    <ModelVersions />
  </a-modal>

  <a-row :gutter="[8, 8]" class="p-4 h-100" style="position: relative">
    <a-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="24"
      class="d-flex justify-content-end"
      style="
        position: absolute;
        top: 16px;
        right: 16px;
        z-index: 1;
        padding: 8px;
      "
    >
    </a-col>

    <a-col :xs="24" :sm="24" :md="24" :lg="12" class="d-flex flex-column mt-5">
      <AddNewTask @onCancelEditTask="resetProcessState" />
    </a-col>

    <a-col :xs="24" :sm="24" :md="24" :lg="12" class="d-flex flex-column mt-5">
      <TaskList />
    </a-col>
  </a-row>
</template>

<script>
import {
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons-vue';
import VideoService from 'src/services/videos';
import types from 'src/store/mutation-types';
import { mapActions, mapGetters } from 'vuex';
import ModelVersions from './ModelVersions/ModelVersions.vue';
import Devices from './ProjectorRegions/Devices.vue';
import RegionDefinition from './RegionDefinition/RegionDefinition.vue';
import SimilarSteps from './SimilarSteps/SimilarSteps.vue';
import StepTime from './StepTime/StepTime.vue';
import AddNewTask from './Task/AddNewTask.vue';
import TaskList from './Task/TaskList.vue';
import TaskParameters from './TaskParameters/TaskParameters.vue';
import AddTranslation from './Translation/AddTranslation.vue';
import UploadStepMedia from './UploadStepMedia/UploadStepMedia.vue';

export default {
  components: {
    TaskParameters,
    StepTime,
    TaskList,
    AddNewTask,
    SimilarSteps,
    AddTranslation,
    RegionDefinition,
    UploadStepMedia,
    ModelVersions,
    Devices,
    PlusOutlined,
    CloseOutlined,
    DeleteOutlined,
  },
  inject: ['toast'],

  data() {
    return {
      showPolicyModal: false,
      types,
      orgTagsModal: false,
      isLoadingTag: false,
      orgTags: [],
      tagName: '',
      tagDesc: '',
      isCreatingObject: false,
    };
  },

  computed: {
    ...mapGetters([
      'allTasks',
      'editModes',
      'isTaskEdit',
      'organization',
      'similarSteps',
      'selectedTask',
      'isTranslation',
      'currentEditMode',
      'isStepTimeExist',
      'isPolicyAccepted',
      'showStepTimeModal',
      'isTranslationEdit',
      'showTaskParamsModal',
      'showSimilarStepsModal',
      'showRegionDefinitionModal',
      'showAssociateRegionModal',
      'showModelVersionsModal',
      'showAnnotationObjectsModal',
      'showProjectorRegionModal',
      'showUploadMediaModal',
      'negativeSteps',
      'optionalSteps',
      'organizationTags',
      'tagIdToNameMap',
    ]),
  },

  created() {
    this.getAllTasks();
    if ([false, 'false'].includes(this.isPolicyAccepted))
      this.showPolicyModal = true;
  },

  methods: {
    ...mapActions([
      'showModal',
      'userLogout',
      'setTaskId',
      'addNewTask',
      'getAllTasks',
      'resetProcessState',
      'setProcesses',
      'updateSingleTask',
      'deleteSingleTask',
      'fetchTaskDetails',
      'cancelTranslation',
      'closeProjectorModal',
      'handleAcceptPolicy',
      'setProcessesTranslation',
      'showModal',
      'fetchOrganizationTags',
      'addNewTag',
      'removeTag',
    ]),

    resetStates() {
      this.setProcesses([]);
      this.setProcessesTranslation([]);
      this.cancelTranslation();
    },

    openTagsModal() {
      this.orgTagsModal = true;
      this.fetchOrganizationTags();
    },

    async handleAddOrgTag() {
      const maxLength = 30;
      const validCharsPattern = /^[a-zA-Z0-9 _-]+$/;

      if (this.tagName.length > maxLength) {
        this.toast.error(`Tag must be ${maxLength} characters or fewer.`);
        return;
      }

      if (!validCharsPattern.test(this.tagName)) {
        this.toast.error(
          'Tag can only contain letters, numbers, spaces, hyphens (-), and underscores (_).'
        );
        return;
      }

      this.isLoadingTag = true;
      const payload = {
        tag_name: this.tagName,
        descript: this.tagDesc || 'default',
        organization: this.organization,
      };
      try {
        const [error, data] = await VideoService.createOrganizationTag(payload);
        this.isLoadingTag = false;
        if (error) {
          const errorMessage =
            error?.response?.data?.tag_name[0] || 'Unable to add tag!';
          this.toast.error(errorMessage);
          return;
        }

        this.addNewTag(data);
        this.tagName = '';
        this.tagDesc = '';
      } catch (err) {
        this.isLoadingTag = false;
        const errorMessage =
          err.response?.data?.message || 'An unexpected error occurred';
        this.toast.error(errorMessage);
      }
    },

    async handleDeleteTag(id) {
      this.isLoadingTag = true;
      const [error] = await VideoService.deleteOrganizationTag(id);
      this.isLoadingTag = false;
      if (error) {
        this.toast.error(`Unable to delete ${this.tagIdToNameMap[id]} tag!`);
        return;
      }
      this.removeTag(id);
    },
  },
};
</script>
<style>
.color-box {
  width: 20px;
  height: 10px;
  display: inline-block;
}

.ant-modal-body {
  padding: 1em;
}
</style>
