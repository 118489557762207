// const requireModule = require.context('.', false, /\.vue$/); // Get svg files inside modules folder

export const steps = [
  {
    title: 'Define Objects and Static Regions',
    component: 'DefineObjectsAndStaticRegion',
    nextButton: { text: 'Label Objects', icon: 'ArrowRightOutlined' },
  },
  {
    title: 'Label Objects',
    component: 'LabelObjects',
    key: 'label_objects',
    nextButton: {
      text: 'Label Static Regions',
      icon: 'ArrowRightOutlined',
    },
    prevButton: {
      text: 'Define Objects and Static Regions',
      icon: 'ArrowLeftOutlined',
    },
  },
  {
    title: 'Label Static Regions',
    component: 'LabelStaticRegions',
    key: 'label_static_regions',
    nextButton: {
      text: 'Label Plane Regions',
      icon: 'ArrowRightOutlined',
    },
    prevButton: { text: 'Label Objects', icon: 'ArrowLeftOutlined' },
  },
  {
    title: 'Label Plane Regions',
    component: 'LabelPlaneRegions',
    key: 'label_plane_regions',
    nextButton: {
      text: 'Associate Steps',
      icon: 'ArrowRightOutlined',
    },
    prevButton: { text: 'Label Static Regions', icon: 'ArrowLeftOutlined' },
  },
  {
    title: 'Associate Steps',
    component: 'AssociateSteps',
    key: 'associate_steps',
    nextButton: {
      text: 'Train Model',
      icon: 'ArrowRightOutlined',
    },
    prevButton: { text: 'Label Plane Regions', icon: 'ArrowLeftOutlined' },
  },
  {
    title: 'Train Model',
    component: 'TrainModel',
    key: 'train_model',
    nextButton: {
      text: 'Done',
      icon: 'CheckOutlined',
    },
    prevButton: { text: 'Associate Steps', icon: 'ArrowLeftOutlined' },
  },
  // {
  //   title: 'Associate Relation',
  //   component: 'RelationSteps',
  //   key: 'relation_steps',
  //   nextButton: {
  //     text: 'Done',
  //     icon: 'CheckOutlined',
  //   },
  //   prevButton: { text: 'Associate Steps', icon: 'ArrowLeftOutlined' },
  // },
];

export const columns = [
  {
    title: 'Thumbnail',
    dataIndex: 'thumbnail_path',
    key: 'thumbnail_path',
  },
  {
    title: 'Filename',
    dataIndex: 'fileName',
    key: 'fileName',
  },
  {
    title: 'Duration',
    dataIndex: 'duration',
    key: 'duration',
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
  },
];

export const getTaskODSteps = (is3D) => {
  if (is3D) return steps;
  return steps
    .filter((s) => s.key !== 'label_plane_regions')
    .map((s) =>
      s.key === 'label_static_regions'
        ? { ...s, nextButton: { ...s.nextButton, text: 'Associate Steps' } }
        : s.key === 'associate_steps'
        ? {
            ...s,
            prevButton: { ...s.prevButton, text: 'Label Static Regions' },
          }
        : s
    );
};

// export const getComponents = () => {
//   const components = {};

//   requireModule.keys().forEach((filePath) => {
//     if (filePath === './ObjectDetection.vue') return;

//     const fileName = filePath.replace('.vue', '').replace('./', '');

//     components[`${fileName}`] = requireModule(filePath).default['__file'];
//   });
//   return components;
// };

export const shortcutKeys = {
  w: {
    handler: 'setNewObjectConfigToDraw',
    insensitiveKey: true,
    action: 'Draw Shape',
  },
  a: {
    ctrl: false,
    handler: 'handlePreviousImage',
    insensitiveKey: true,
    action: 'Go to previous image',
  },
  d: {
    handler: 'handleNextImage',
    insensitiveKey: true,
    action: 'Go to next image',
  },
  Delete: { handler: 'deleteObject', action: 'Delete Shape' },
  '+': {
    ctrl: true,
    alt: true,
    handler: 'handleLayerZoomIn',
    action: 'Zoom In',
  },
  '-': {
    ctrl: true,
    alt: true,
    handler: 'handleLayerZoomOut',
    action: 'Zoom Out',
  },
  f: {
    ctrl: true,
    handler: 'handleLayerFitWindow',
    insensitiveKey: true,
    action: 'Fit Window',
  },
  q: {
    ctrl: true,
    handler: 'handleSelectAll',
    insensitiveKey: true,
    action: 'Select All',
  },
  s: {
    ctrl: true,
    handler: 'saveCurrentImageAnnotation',
    insensitiveKey: true,
    action: 'Save annotation',
  },
  b: {
    ctrl: true,
    handler: 'saveEmptyAnnotation',
    insensitiveKey: true,
    action: 'Save empty annotation',
  },
  c: {
    ctrl: true,
    handler: 'copySelectedShapes',
    insensitiveKey: true,
    action: 'Copy Shapes',
  },
  v: {
    ctrl: true,
    handler: 'pasteShapes',
    insensitiveKey: true,
    action: 'Paste Shapes',
  },
  x: {
    ctrl: true,
    handler: 'renderLastExistingObjectList',
    insensitiveKey: true,
    action: 'Apply Previous Labels',
  },
  click: {
    ctrl: true,
    hold: true,
    action: 'Multi Select',
  },
  ArrowUp: { handler: 'moveSelectedNodesup', action: 'Move up' }, // Define ArrowUp key
  ArrowDown: { handler: 'moveSelectedNodesdown', action: 'Move down' }, // Define ArrowUp key
  ArrowLeft: { handler: 'moveSelectedNodesleft', action: 'Move left' }, // Define ArrowUp key
  ArrowRight: { handler: 'moveSelectedNodesright', action: 'Move right' }, // Define ArrowUp key
};

export const shortcutKeysData = () => {
  let data = [];
  for (const [
    key,
    { ctrl, alt, insensitiveKey, action, hold },
  ] of Object.entries(shortcutKeys)) {
    let command = '';
    if (hold) command += 'Press and hold ';
    if (ctrl) command += 'Ctrl + ';
    if (alt) command += 'Alt + ';
    if (insensitiveKey) command += `${key.toUpperCase()}`;
    else command += key;
    data.push({ keys: command, action: action });
  }
  return data;
};

export const TransformerConfig = {
  keepRatio: false,
  ignoreStroke: true,
  rotateEnabled: false,
  flipEnabled: false,
  anchorSize: 12,
  enabledAnchors: [
    'top-left',
    'top-right',
    'bottom-left',
    'bottom-right',
    'middle-left',
    'middle-right',
    'top-center',
    'bottom-center',
  ],
};

export const ObjectSettingsConfig = [
  'labelColor',
  'labelSize',
  'annotationObject',
  'staticFrame',
];

export const ModelVersionAiAnnotationColumns = [
  {
    title: 'Time Trained',
    dataIndex: 'date',
    key: 'date',
    width: '25%',
    align: 'center',
  },
  {
    title: 'Version',
    dataIndex: 'version_number',
    key: 'version',
    width: '25%',
    align: 'center',
  },
  {
    title: 'Version Description',
    dataIndex: 'description',
    key: 'description',
    align: 'center',
  },
];

export const taskObjectListColumns = [
  {
    title: 'Objects',
    dataIndex: 'name',
    key: 'name',
  },
];

export const modelTypeSelection = [
  { label: 'Hands', value: 'hands' },
  { label: 'Objects', value: 'objects' },
];

export const longRunningTaskStatus = {
  PENDING: 'pending',
  FAILED: 'failed',
  FINISHED: 'finished',
  STARTED: 'started',
};

export const annotatedColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '30%',
    fixed: 'left',
  },
  {
    title: 'Model Version',
    dataIndex: 'modelVersion',
    key: 'modelVersion',
    width: '25%',
    fixed: 'left',
  },
  {
    title: 'Date Time',
    dataIndex: 'dateTime',
    key: 'dateTime',
    fixed: 'left',
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    width: '10%',
    fixed: 'right',
  },
];

export const failedAnnotationColumns = [
  ...annotatedColumns.slice(0, 3),
  {
    title: 'Failed Reason',
    dataIndex: 'error',
    key: 'error',
    width: '30%',
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    fixed: 'right',
  },
];
