<template>
    <a-modal
      v-model:visible="displayModal"
      title="Shortcut Keys Info"
      @cancel="handleCloseModal"
    >
      <a-table :data-source="dataSource" :columns="columns" :pagination="false" />
      <template #footer>
        <a-button @click="handleCloseModal" type="primary">
          Close
        </a-button>
      </template>
    </a-modal>
  </template>
  <script>
  import { shortcutKeysData } from './config';
  export default {
    props: ['openModal'],
    emits: ['closeModal'],
  
    computed: {
      displayModal() {
        if (this.openModal) return true;
        return false;
      },
  
      dataSource() {
        return shortcutKeysData();
      }
    },
  
    data() {
      return {
        columns: [
          {
            title: 'Keys',
            dataIndex: 'keys',
            key: 'keys'
          },
          {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action'
          }
        ]
      };
    },
  
    methods: {
      handleCloseModal() {
        this.$emit('closeModal', false);
      }
    }
  };
  </script>
  
