<template>
    <div :key="$route.fullPath">
    <a-button
        type="primary"
        @click="handleTrainModel"
        style="margin-left: 30px"
        class="next-step-button"
    >
        Train Model
    </a-button>
    </div>
</template>
<script>
import {
  ArrowRightOutlined,
  CheckOutlined,
  ReloadOutlined
} from '@ant-design/icons-vue';

export default {
  components: {
    ReloadOutlined,
    ArrowRightOutlined,
    CheckOutlined
  },
  data() {
    return {
    };
  },

  methods: {
    handleTrainModel() {
        this.$router.push({path: "/user/train"});
    }
  }
};
</script>
<style>
.next-step-button {
  display: flex;
  align-items: center;
}
.next-step-button .anticon {
  order: 2;
  margin-left: 8px;
}
</style>