<!-- Popup.vue -->
<template>
  <ArrowLeftOutlined @click="close" />

  <div style="padding-top: 5px;">
    <a-row>
      <a-col :span="16" style="padding: 4px;">
        <b style="margin-left: 10px;">Code </b>
        <Codemirror
          v-if="showcode"
          v-model:value="code"
          :options="cmOptions"
          border
          placeholder="Code"
          :height="400"
          @change="change"
      /></a-col>
      <a-col :span="8" style="padding: 4px;">
        <b style="margin-left: 15px;">Code Snippets </b>
        <div style="font-size:medium; padding-top: 5px; padding-left: 5px;">
          <p
            v-if="stepname == '__init__'"
            class="scrollable-paragraph-init"
            style="height: 400px;"
          >
          <div style="font-size: small;">
            
             "Detection.class_names : Dictionary" class_name variable contains a Dictionary 
              where keys represents the objects in OD pipline and value represents index of that object.
              <div>
               <a-table :columns="columns" :data-source="items" row-key="index" :pagination=false size="small" 
            
               ></a-table>
             </div>
          </div>
     
        </p>
          <p v-else class="scrollable-paragraph">
            <div style="font-size: small;">
              1. "classification_point : Dictionary" contains classification points that are updated real time while training.
            <br>
              2. Variable "current_detection : ArrayofInt" it contains the
            indexes representing current detected objects.
            <div>
            <br>
            <div>
              Each integer in the array represents objects as follows.
            </div>
            <br>
            <div>
              <a-table :columns="columns" :data-source="items" row-key="index" :pagination=false size="small"  ></a-table>
             </div>
            </div>

            </div>
          </p>

          <Codemirror
            v-if="showcode && stepname != '__init__'"
            v-model:value="demo_code"
            :options="cmOptions"
            border
            placeholder="Code"
            :height="170"
            @change="change"
          />
        </div>
      </a-col>
    </a-row>
  </div>
  <div
    style="text-align:right; padding: 5px; display: flex; justify-content:flex-end;"
  >
    <div
      v-if="compileStatement.length > 0"
      :style="[
        compileStatus == 500
          ? { color: 'rgb(232, 12, 12)', padding: '5px' }
          : { color: 'rgb(28, 193, 28)', padding: '5px' }
      ]"
    >
      {{ compileStatement }}
      <CheckOutlined v-if="compileStatus == 200" color="green" />
      <CloseOutlined v-if="compileStatus == 500" color="red" />

      <CaretDownOutlined v-if="full_trace == false" @click="showTrace()" />
      <CaretUpOutlined v-else @click="showTrace()" />
      <p v-if="full_trace">
        {{ fulltraceStatement }}
      </p>
    </div>

    <a-button
      type="primary"
      style="border-color: black; color: #f2ebeb;"
      @click="saveCode()"
      >save</a-button
    >
  </div>
</template>

<script>
import Codemirror from 'codemirror-editor-vue3';

// placeholder
import 'codemirror/addon/display/placeholder.js';

// language
import 'codemirror/mode/python/python.js';

// placeholder
import 'codemirror/addon/display/placeholder.js';
// theme
import 'codemirror/theme/dracula.css';
import { Table } from 'ant-design-vue';

import { ref } from 'vue';
import {
  ArrowLeftOutlined,
  CheckOutlined,
  CloseOutlined,
  CaretUpOutlined,
  CaretDownOutlined
} from '@ant-design/icons-vue';
import axios from 'axios';
import { message } from 'ant-design-vue';
import { mapActions, mapGetters } from 'vuex';
import { deepClone } from 'src/utils/task';

export default {
  components: {
    Codemirror,
    ArrowLeftOutlined,
    CheckOutlined,
    CloseOutlined,
    CaretUpOutlined,
    CaretDownOutlined,
    'a-table': Table,
  },
  props: {
    stepname: String,
    close: Function,
    codevalue: String
  },
  setup() {
    return {
      code: ref(``),
      cmOptions: {
        mode: 'python', // Python language mode
        theme: 'dracula', // Theme,
        version: '3'
      }
    };
  },
  computed: {
    ...mapGetters(['taskObjects', 'selectedTask'])
  },
  data() {
    return {
      showcode: false,
      compileStatement: '',
      fulltraceStatement: '',
      compileStatus: 200,
      full_trace: false,
      demo_code: '',
      items: [
        // Add more data as needed
      ],
      columns: [
        { title: 'Key', dataIndex: 'index', key: 'index' },
        { title: 'Objects', dataIndex: 'value', key: 'value' },
      ],
    };
  },
  async taskObjects() {
    await this.updateTaskObjectsAndStaticRegions();
  },
  async mounted() {
    await this.updateTaskObjectsAndStaticRegions();

    await this.getTaskObjects(this.selectedTask);
    let functionName = '';
    this.stepname.split(' ').forEach(value => {
      if (functionName.length == 0) functionName = value;
      else functionName = functionName + '_' + value;
    });

    functionName = functionName.replace(/[^a-zA-Z0-9-]/g, '_').replace(/-/g, '_');

    if (this.codevalue.length < 1) {
      if (this.stepname == '__init__') {
        this.code = ref(`# Custom function for ${this.stepname}
def ${this.stepname}(self):
  # write your code here!
  pass
`);
      } else {
        this.code = ref(`# Custom function for ${this.stepname}
def detector_${functionName}(self,current_detection):
  # write your code here!
  return False`);
      }
    } else {
      this.code = ref(`${this.codevalue}`);
    }
    this.demo_code = `def detector_${functionName}(self,current_detection, classification_point):
  # write your code here!
  if 0 in current_detection:
	  self.count = self.count + 1
  return self.count > 100`;
    this.showcode = true;
  },

  methods: {
    ...mapActions(['getTaskObjects']),

    async updateTaskObjectsAndStaticRegions() {
      const taskObjects = deepClone(this.taskObjects);
      const objects = this.sortObjects(
        taskObjects.filter(
          obj => !obj.cordinates_of_static_object && !obj.is_static
        )
      );
      
      objects.forEach((item , index)=>{
        console.log(item)
        this.items.push({'index':index , "value": item.name})
      })


    },
    sortObjects(objects) {
      //place hands and faces at the end of list
      const priority = {
        hands: 1,
        faces: -1
      };

      objects.sort(function(a, b) {
        if (
          priority.hasOwnProperty(a.name) &&
          priority.hasOwnProperty(b.name)
        ) {
          return priority[b.name];
        }
        if (priority.hasOwnProperty(a.name)) {
          return -1;
        }
      });
      return objects;
    },
    showTrace() {
      this.full_trace = !this.full_trace;
    },
    async compileCustomCode() {
      let res = {};
      try {
        res = await axios.post(
          'https://yv9l0pz9u4.execute-api.us-east-2.amazonaws.com/python-code-compiler',
          {
            code: this.code
          }
        );
      } catch (e) {
        res['statusCode'] = 500;
        res['data'] = {};
        res['data']['message'] = 'Internal Server Error';
      }
      return res.data;
    },
    async saveCode() {
      const lines = this.code.split('\n');
      lines[0] = `# Custom function for ${this.stepname}`;
      this.code = lines.join('\n');
      var res = await this.compileCustomCode();
      if (res.statusCode != 200) {
        this.compileStatus = res.statusCode;
        this.compileStatement = res.message;
        this.fulltraceStatement = res.error_trace;
        console.log(this.compileStatement);
      } else {
        this.compileStatement = 'All test cases executed successfully';
        this.compileStatus = res.statusCode;
        this.$emit('saveCode', this.code, this.stepname);
        this.close();
      }
    }

    // async saveCode() {
    //   const payload = this.getPayload(file, modelS3Key);
    //   const config = this.getUploadConfig(file.name);
    //   const [error] = await S3Service.uploadFile(payload, false, config);
    // }
  }
};
</script>

<style scoped>
/* Add your styling here */
.popup {
  /* Styling for the overlay */
}
.popup-content {
  /* Styling for the popup content */
}

.scrollable-paragraph {
  max-height: 210px;
  overflow-y: auto;
  padding: 10px;
}
.scrollable-paragraph-init {
  max-height: 600px;
  overflow-y: auto;
  padding: 10px;
}
.custom-table td {
  padding: 2px 4px;
}
</style>
