<template>
  <a-table
    id="inspection-videos-table"
    :columns="columns"
    :dataSource="tags"
    :loading="isTagsLoading"
    class="m-0"
    :pagination="pagination"
    @change="handleTableChange"
  >
    <template #headerCell="{ title }">
      <span
        :id="'inspect-' + title + '-header-cell'"
        class="d-flex justify-content-center"
      >
        {{ title }}
      </span>
    </template>
    <template #bodyCell="{ text, record, column, index }">
      <span v-if="column.dataIndex === 'operation'">
        <a-button
          type="primary"
          block
          class="mt-2"
          @click="
            () => {
              selectedTag = deepClone(record);
              updateModalVisible = true;
            }
          "
        >
          <template #icon>
            <form-outlined />
          </template>
          Update Tag
        </a-button>
        <a-button type="danger" block class="mt-2" @click="deleteTag(record)">
          <template #icon>
            <delete-outlined />
          </template>
          Delete
        </a-button>
      </span>
    </template>
  </a-table>
  <update-tag-modal
    :visible="updateModalVisible"
    :selectedTag="selectedTag"
    :tag-address-tree="tagAddressTree"
    @refresh-tag-list="refreshTagList()"
    @close="updateModalVisible = false"
  ></update-tag-modal>
  <!-- <tag-parameters-modal
    :visible="tagModalVisible"
    :selectedTag="selectedTag"
    @close="tagModalVisible = false"
  ></tag-parameters-modal>
  <task-parameters-modal
    :visible="taskModalVisible"
    :selectedTag="selectedTag"
    @close="taskModalVisible = false"
  ></task-parameters-modal> -->
</template>

<script>
import { columns } from './config';
import IoTService from '../../../../services/iot';
import UpdateTagModal from './UpdateTagModal.vue';
import {
  TagsOutlined,
  FormOutlined,
  DeleteOutlined,
  SearchOutlined,
} from '@ant-design/icons-vue';

export default {
  components: {
    TagsOutlined,
    FormOutlined,
    DeleteOutlined,
    UpdateTagModal,
    SearchOutlined,
  },
  inject: ['toast', 'deepClone'],
  props: ['isTagsLoading', 'tags', 'tagCount', 'currentPage', 'tagAddressTree'],
  emits: ['getTags', 'refreshTagList', 'setcurrentPage'],

  data() {
    return {
      state: {
        pageSize: 10,
        current: 1,
        searchText: '',
      },
      selectedTag: {},
      columns: columns,
      updateModalVisible: false,
    };
  },

  computed: {
    pagination() {
      return {
        total: this.tagCount,
        current: this.currentPage,
        pageSize: 10,
        position: ['bottomCenter'],
      };
    },
  },

  methods: {
    handleTableChange(page) {
      const { current } = page;
      this.$emit('setcurrentPage', current);
      console.log(current);
    },
    refreshTagList() {
      this.$emit('refreshTagList');
    },

    async deleteTag(record) {
      this.isLoading = true;
      const [error, _] = await IoTService.deleteOPCTag(record.id);
      if (error) {
        return this.toast.error('Failed to delete OPC Tag. Try again', {
          timeout: 3000,
        });
      }
      this.toast.success('Tag deleted Successfully!');
      await this.$emit('refreshTagList');
      this.isLoading = false;
    },
  },
};
</script>

<style></style>
