<template>
  <div
    style="min-height: 250px;"
    class="d-flex align-items-center justify-content-center"
  >
    <div
      class="d-flex align-items-center justify-content-center"
      v-if="loading"
    >
      <a-spin size="large" />
    </div>

    <img
      v-else-if="fileType === 'image'"
      :src="fileURL"
      :alt="fileURL"
      :key="'img' + fileURL"
      style="width:350px;height:auto"
    />

    <video
      v-else-if="fileType === 'video'"
      ref="video"
      :src="fileURL"
      :key="fileURL"
      crossorigin="anonymous"
      muted
      controls
      style="width: 380px;"
    ></video>

    <a-result v-else status="warning" title="No Preview Available"> </a-result>
  </div>
</template>
<script>
import VideoService from 'src/services/videos';
import { mapGetters } from 'vuex';

export default {
  props: ['record', 'stepIndex'],
  inject: ['toast'],
  setup: () => ({
    allowedImages: ['png', 'jpg', 'jpeg'],
    allowedVideos: ['mp4']
  }),
  data() {
    return {
      fileURL: '',
      fileType: '',
      loading: true
    };
  },
  mounted() {
    this.handleGetFileUrl();
  },
  computed: {
    ...mapGetters(['taskStepsMediaObject', 'organization'])
  },
  methods: {
    async handleGetFileUrl() {
      const obj = this.taskStepsMediaObject[this.stepIndex];
      if (this.allowedImages.includes(obj.extension)) this.fileType = 'image';
      else if (this.allowedVideos.includes(obj.extension))
        this.fileType = 'video';

      const payload = {
        bucket_name: `${this.organization}-training`,
        object_path: obj.path
      };
      const [error, data] = await VideoService.getPresignedUrl(payload);
      this.loading = false;
      if (error) {
        this.toast.error('Error occurred in fetching file');
        return;
      }
      this.fileURL = data.presigned_url;
    }
  }
};
</script>
