export const telemetryParams = {
  requiredStepsForCycle: [
    {
      paramType: 'telemetryParams',
      key: 'required_steps_for_start',
      title: 'Required Step for Cycle Start'
    },
    {
      paramType: 'telemetryParams',
      key: 'required_steps_for_end',
      title: 'Required Step for Cycle End'
    }
  ],

  cycleParams: [
    {
      paramType: 'telemetryParams',
      key: 'min_percentage_of_steps_required_to_end_cycle',
      title: 'Minimum Percentage Steps required for Cycle End (%)'
    },
    {
      paramType: 'telemetryParams',
      key: 'minimum_cycle_time',
      title: 'Minimum Cycle Time (s)'
    },
    {
      paramType: 'telemetryParams',
      key: 'maximum_cycle_time',
      title: 'Maximum Cycle Time  (s)'
    }
  ]
};

export const trainingParams = [
  // {
  //   paramType: 'trainingParams',
  //   key: 'augmentations',
  //   title: 'Augmentations',
  //   type: 'select',
  // },
  {
    paramType: 'trainingParams',
    key: 'model_choice',
    // key: 'model_type',
    title: 'Model Choice',
    type: 'select'
  },
  // {
  //   paramType: 'trainingParams',
  //   key: 'threshold',
  //   title: 'Background Frame Threshold',
  //   type: 'input',
  //   min: 1,
  //   max: 100,
  // },
  // {
  //   paramType: 'trainingParams',
  //   key: 'spatial_model_iterations',
  //   title: 'Spatial Model Iteration',
  //   type: 'input',
  //   min: 5000,
  //   max: 20000,
  // },
  // {
  //   paramType: 'trainingParams',
  //   key: 'spatial_model_batch_size',
  //   title: 'Spatial Model Batch Size',
  //   type: 'input',
  //   min: 4,
  //   max: 32,
  // },
  // {
  //   paramType: 'trainingParams',
  //   key: 'contextual_model_iterations',
  //   title: 'Contextual Model Iteration',
  //   type: 'input',
  //   min: 20,
  //   max: 100,
  // },
  // {
  //   paramType: 'trainingParams',
  //   key: 'contextual_model_batch_size',
  //   title: 'Contextual Model Batch Size',
  //   type: 'input',
  //   min: 4,
  //   max: 32,
  // },
  {
    paramType: 'trainingParams',
    key: 'verify_threshold',
    title: 'Verify Threshold',
    type: 'input',
    min: 1,
    max: Number.MAX_SAFE_INTEGER
  }
];
