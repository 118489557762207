export const OpcTagsColumn = [
  {
    title: 'Tag Name',
    dataIndex: 'tag_id',
    key: 'tag_id',
    width: '25%'
  },
  {
    title: 'Data Type',
    dataIndex: 'data_type',
    key: 'data_type',
    width: '20%'
  },
  {
    title: 'Access Level',
    dataIndex: 'editable',
    key: 'editable',
    width: '15%'
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    width: '40%'
  }
];

export const OpcTriggers = [
  {
    value: 'cycle_start',
    label: 'Cycle Start'
  },
  {
    value: 'cycle_end',
    label: 'Cycle End'
  },
  {
    value: 'missed_step',
    label: 'Missed Step'
  },
  {
    value: 'step_completion',
    label: 'Step Completion'
  },
  {
    value: 'verify_step',
    label: 'Verify Step'
  },
  {
    value: 'mark_step_completion',
    label: 'Mark Step Completion'
  }
];
