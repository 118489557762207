<template>
  <a-row :gutter="[0, 16]">
    <a-col span="24">
      <a-tabs
        v-model:activeKey="currentTab"
        style="height: 70vh"
        type="card"
        class="w-100"
        size="small"
      >
        <!-- AI Annotated Pane -->
        <a-tab-pane id="ai-annotated-tab" key="annotated">
          <template #tab>
            <a-typography-text strong>
              AI Annotated: &nbsp;
              <a-tag id="ai-annotated-count" color="blue">
                {{ annotatedList.length }}
              </a-tag>
            </a-typography-text>
          </template>
          <a-table
            :columns="annotatedColumns"
            :data-source="annotatedList"
            bordered
            :loading="fetchingAnnotations"
            :scroll="{ y: 450, x: true }"
          >
            <template #bodyCell="{ record, index, column, text }">
              <template v-if="column.dataIndex === 'name'">
                <strong>{{ record.name }} </strong>
              </template>
              <template v-if="column.dataIndex === 'modelVersion'">
                {{ record?.modelVersion ? record.modelVersion : 'N/A' }}
              </template>
              <template v-if="column.dataIndex === 'dateTime'">
                {{ dateHelper.formatDate(record.dateTime) }}
              </template>
              <template v-if="column.dataIndex === 'action'">
                <a-popconfirm
                  title="Are you sure you want to delete this record?"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="
                    handleDeleteTask(
                      record,
                      index,
                      longRunningTaskStatus.FINISHED
                    )
                  "
                >
                  <a-button type="danger">
                    <template #icon>
                      <DeleteOutlined />
                    </template>
                  </a-button>
                </a-popconfirm>
              </template>
            </template>
          </a-table>
        </a-tab-pane>

        <!-- Pending Annotation Pane -->
        <a-tab-pane id="pending-annotation-tab" key="pending">
          <template #tab>
            <a-typography-text strong>
              Pending Annotation: &nbsp;
              <a-tag id="pending-annotation-count" color="blue">
                {{ pendingAnnotationList.length }}
              </a-tag>
            </a-typography-text>
          </template>
          <a-table
            :columns="annotatedColumns"
            :data-source="pendingAnnotationList"
            bordered
            :loading="fetchingAnnotations"
            :scroll="{ y: 450, x: true }"
          >
            <template #bodyCell="{ record, index, column, text }">
              <template v-if="column.dataIndex === 'name'">
                <strong>{{ record.name }} </strong>
              </template>
              <template v-if="column.dataIndex === 'modelVersion'">
                {{ record?.modelVersion ? record.modelVersion : 'N/A' }}
              </template>
              <template v-if="column.dataIndex === 'dateTime'">
                {{ dateHelper.formatDate(record.dateTime) }}
              </template>
              <template v-if="column.dataIndex === 'action'">
                <a-popconfirm
                  title="Are you sure you want to delete this record?"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="
                    handleDeleteTask(
                      record,
                      index,
                      longRunningTaskStatus.PENDING
                    )
                  "
                >
                  <a-button type="danger">
                    <template #icon>
                      <DeleteOutlined />
                    </template>
                  </a-button>
                </a-popconfirm>
              </template>
            </template>
          </a-table>
        </a-tab-pane>

        <!-- Failed Annotation Pane -->
        <a-tab-pane id="failed-annotation-tab" key="failed">
          <template #tab>
            <a-typography-text strong>
              Failed Annotation: &nbsp;
              <a-tag id="failed-annotation-count" color="blue">
                {{ failedAnnotationList.length }}
              </a-tag>
            </a-typography-text>
          </template>
          <a-table
            :columns="failedAnnotationColumns"
            :data-source="failedAnnotationList"
            bordered
            :loading="fetchingAnnotations"
            :scroll="{ y: 450, x: true }"
          >
            <template #bodyCell="{ record, index, column, text }">
              <template v-if="column.dataIndex === 'name'">
                <strong>{{ record.name }} </strong>
              </template>
              <template v-if="column.dataIndex === 'modelVersion'">
                {{ record?.modelVersion ? record.modelVersion : 'N/A' }}
              </template>
              <template v-if="column.dataIndex === 'dateTime'">
                {{ dateHelper.formatDate(record.dateTime) }}
              </template>
              <template v-if="column.dataIndex === 'error'">
                {{ record.error }}
              </template>
              <template v-if="column.dataIndex === 'action'">
                <a-popconfirm
                  title="Are you sure you want to delete this record?"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="
                    handleDeleteTask(
                      record,
                      index,
                      longRunningTaskStatus.FAILED
                    )
                  "
                >
                  <a-button type="danger">
                    <template #icon>
                      <DeleteOutlined />
                    </template>
                  </a-button>
                </a-popconfirm>
              </template>
            </template>
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </a-col>
  </a-row>
</template>

<script>
import { DeleteOutlined } from '@ant-design/icons-vue';
import dateHelper from 'src/components/shared/Helpers/dateHelper.js';
import LRTService from 'src/services/longRunningTask';
import { mapGetters } from 'vuex';
import {
  annotatedColumns,
  failedAnnotationColumns,
  longRunningTaskStatus,
} from './config.js';
export default {
  components: {
    DeleteOutlined,
  },

  inject: ['toast'],

  setup() {
    return {
      annotatedColumns,
      longRunningTaskStatus,
      failedAnnotationColumns,
      dateHelper,
    };
  },

  data() {
    return {
      interval: null,
      currentTab: 'annotated',
      fetchingAnnotations: false,
      aiAnnotationList: [],
      annotatedList: [],
      pendingAnnotationList: [],
      failedAnnotationList: [],
    };
  },

  computed: {
    ...mapGetters(['selectedTask']),
  },

  async mounted() {
    await this.getAiAnnotations();
    this.interval = setInterval(this.getAiAnnotations, 10 * 1000);
  },

  unmounted() {
    clearInterval(this.interval);
    this.interval = null;
  },

  methods: {
    resetAnnotationList() {
      this.annotatedList = [];
      this.pendingAnnotationList = [];
      this.failedAnnotationList = [];
    },

    async getAiAnnotations() {
      this.fetchingAnnotations = true;
      const [error, data] = await LRTService.getLongRunningTasks(false, {
        name: 'auto annotation',
        task: this.selectedTask,
      });
      this.fetchingAnnotations = false;
      if (error) return this.toast.error('Failed to retrieve Ai Annotations.');
      this.resetAnnotationList();
      for (let item of data) {
        const list = this.getListByStatus(item.status);
        if (list === null) continue;
        list.push(this.getAnnotationObject(item));
      }
    },

    getAnnotationObject(item) {
      return {
        id: item.id,
        name: item.details?.name,
        status: item.status,
        modelVersion: item.event?.model_version,
        dateTime: item.created_at,
        error: item.error_field,
      };
    },

    getListByStatus(status) {
      switch (status) {
        case longRunningTaskStatus.FINISHED:
          return this.annotatedList;
        case longRunningTaskStatus.PENDING:
          return this.pendingAnnotationList;
        case longRunningTaskStatus.FAILED:
          return this.failedAnnotationList;
        default:
          return null;
      }
    },

    async handleDeleteTask(record, index, status) {
      const list = this.getListByStatus(status);
      if (list === null) return;
      clearInterval(this.interval);
      const element = list.splice(index, 1)[0];
      const [error, _] = await LRTService.deleteLongRunningTask(record.id);
      if (error) {
        list.splice(index, 0, element);
        this.toast.error('Failed to deleted item.');
      }

      this.interval = setInterval(this.getAiAnnotations, 10 * 1000);
    },
  },
};
</script>
