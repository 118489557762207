<template>
  <a-list
    bordered
    class="file-list"
    size="small"
    :data-source="fileDataList"
    item-layout="vertical"
  >
    <template #header>
      <div>
        <span>File List</span>
      </div>

      <!-- Search files -->
      <a-input
        placeholder="Search File"
        v-model:value="searchFile"
        class="w-100"
        @blur="handleFileInputBlur"
        @focus="handleFileInputFocus"
        @change="handleInputChange"
      >
      </a-input>
    </template>

    <!-- Files List -->
    <template #renderItem="{ item, index }">
      <a-list-item
        :class="{ 'selected-item': selectedItem === index }"
        class="cursor-pointer file-item"
        @click="handleClick(item, index)"
      >
        {{ item.path_to_image.split('/')[3] }}
      </a-list-item>
    </template>
  </a-list>
</template>

<script>
export default {
  props: ['imageList', 'index'],
  emits: ['removeEvents', 'subscribeEvents', 'loadImage'],
  data() {
    return {
      searchFile: '',
      inputFocus: false,
      selectedItem: this.index,
    };
  },
  computed: {
    fileDataList() {
      if (!this.imageList) {
        return [];
      }
      const searchLowerCase = this.searchFile.toLowerCase().trim();
      return this.imageList.filter((file) => {
        let fileName = '';
        try {
          fileName = file.path_to_image.split('/')[3].toLowerCase();
        } catch (error) {
          fileName = file.path_to_image.toLowerCase();
        }
        return fileName.includes(searchLowerCase);
      });
    },
  },
  watch: {
    index(newIndex) {
      this.selectedItem = newIndex;
      this.$nextTick(() => {
        this.scrollToSelectedItem();
      });
    },
  },
  mounted() {
    // Run the scroll logic when the component is first loaded
    this.$nextTick(() => {
      this.scrollToSelectedItem();
    });
  },
  methods: {
    handleClick(imageObj, index) {
      this.selectedItem = index;

      this.$emit('loadImage', imageObj, index);
    },
    handleFileInputBlur(e) {
      this.inputFocus = false;
      this.$emit('subscribeEvents');
    },
    handleInputChange(e) {
      this.searchFile = e.target.value;
    },
    handleFileInputFocus(e) {
      if (this.inputFocus) return;
      this.inputFocus = true;
      this.$emit('removeEvents');
    },
    scrollToSelectedItem() {
      const selectedElement = this.$el.querySelector('.selected-item');
      if (selectedElement) {
        selectedElement.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    },
  },
};
</script>

<style scoped>
.file-list {
  display: flex;
  flex-direction: column;
  height: 40% !important;
  padding: 0px !important;
  margin: 0px !important;
}

.file-list :deep(.ant-list-header) {
  display: flex;
  flex-direction: column;
  border: 0px !important;
  border-bottom: 0px !important;
  padding: 0px !important;
  margin: 0px !important;
}

.file-list > .ant-list-header > div {
  background: lightgray !important;
  height: 40px;
  padding: 8px 16px;
}

.file-list > .ant-list-header span {
  font-weight: 600 !important;
}

.file-list :deep(.ant-spin-nested-loading) {
  flex-grow: 1;
  height: 1px;
  overflow-y: auto;
}

.selected-item {
  background-color: lightgray;
}

.file-item:hover {
  background-color: #f5f5f5; /* Very light gray for hover effect */
}
</style>
