<template>
  <a-card title="Tag Parameters" size="small">
    <a-form
      :form="form"
      :model="form"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
    >
      <br />
      <a-form-item label="Tag name" :rules="[{ required: true }]">
        <a-input
          v-model:value="selectedTag.tagName"
          placeholder="Enter Tag Name"
        />
      </a-form-item>
      <a-form-item label="Tag Address" :rules="[{ required: true }]">
        <a-input
          v-model:value="selectedTag.tagId"
          style="margin-bottom: 8px"
          placeholder="Tag Address"
        />
        <a-tree-select
          v-show="treeData.length"
          :tree-data="treeData"
          @check="checkedItem"
          @select="selectedItem"
          @expand="expandItem"
        >
        </a-tree-select>
      </a-form-item>
    </a-form>
  </a-card>
  <a-card title="Trigger Parameters" size="small">
    <br />
    <a-form :model="form" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
      <a-form-item name="select" label="Trigger On" has-feedback>
        <a-select
          v-model:value="selectedTag.triggerOn"
          placeholder="Please select a trigger"
          allowClear="true"
          @change="handleTriggerChange"
        >
          <a-select-option value="step completion"
            >Step Completion</a-select-option
          >
          <a-select-option value="missed step">Missed Step</a-select-option>
          <a-select-option value="step verification"
            >Step Verification</a-select-option
          >
        </a-select>
      </a-form-item>
      <div v-if="selectedTag.triggerOn == 'step completion'">
        <a-form-item label="Value" :rules="[{ required: true }]">
          <a-input v-model:value="selectedTag.triggerParams.value" />
        </a-form-item>
      </div>
      <div v-if="selectedTag.triggerOn == 'missed step'">
        <a-form-item label="Reset value" :rules="[{ required: true }]">
          <a-input
            v-model:value="selectedTag.triggerParams.onCompletion"
            :rules="[{ required: true }]"
          />
        </a-form-item>
        <a-form-item label="Value" :rules="[{ required: true }]">
          <a-input v-model:value="selectedTag.triggerParams.value" />
        </a-form-item>
      </div>
      <div v-if="selectedTag.triggerOn == 'step verification'">
        <a-form-item label="Comparator" :rules="[{ required: true }]">
          <a-select v-model:value="selectedTag.triggerParams.comparator">
            <a-select-option value="gt">Greater than</a-select-option>
            <a-select-option value="lt">Lesser than</a-select-option>
            <a-select-option value="ge"
              >Greater than or equal to</a-select-option
            >
            <a-select-option value="le"
              >Lesser than or equal to</a-select-option
            >
            <a-select-option value="e">Equals</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Threshold" :rules="[{ required: true }]">
          <a-input v-model:value="selectedTag.triggerParams.threshold" />
        </a-form-item>
      </div>
    </a-form>
  </a-card>
</template>

<script>
import { TreeDataItem } from 'ant-design-vue/es/tree/Tree';
import { log } from 'video.js';

export default {
  props: ['selectedTag', 'tagAddressTree'],
  emits: ['close'],
  data() {
    return {
      selectedKey: null,
      treeData: [],
    };
  },
  watch: {
    // expandedKeys: function(value) {
    //   return this.treeData
    //     .filter((obj) => obj.expand === true)
    //     .map((obj) => obj.key);
    // },
  },
  created() {
    this.treeData = [this.tagAddressTree];
    if (this.treeData.length) {
      for (let i = 0; i < this.treeData.length; i++) {
        this.addKeysInDataTree(this.treeData[i]);
        for (let j = 0; j < this.treeData[i]['children'].length; j++) {
          this.addKeysInDataTree(this.treeData[i]['children'][j]);
          for (
            let k = 0;
            k < this.treeData[i]['children'][j]['children'].length;
            k++
          ) {
            this.addKeysInDataTree(
              this.treeData[i]['children'][j]['children'][k]
            );
            if ('children' in this.treeData[i]['children'][j]['children'][k]) {
              this.treeData[i]['children'][j]['children'][k]['expand'] = true;
              this.treeData[i]['children'][j]['children'][k]['disabled'] = true;
            }
          }
        }
      }
    }
  },
  methods: {
    addKeysInDataTree(tag) {
      if ('children' in tag) {
        tag['expand'] = true;
        tag['disabled'] = true;
        // this.treeData[i]['children'][j]['selectable'] = false;
      }
    },
    expandItem(event) {
      console.log(event);
    },
    checkedItem(event) {
      let extractedTitles = [];

      const keys = event;

      extractedTitles = this.treeData
        .filter((obj) => obj.key in keys)
        .map((obj) => obj.title);
    },
    selectedItem(event) {
      this.selectedTag.tagId = event;
    },

    handleTriggerChange(value) {
      this.selectedTag.triggerParams = {};
      if (value == 'missed step') {
        this.selectedTag.triggerParams.onCompletion = null;
        this.selectedTag.triggerParams.value = null;
      } else if (value == 'step completion') {
        this.selectedTag.triggerParams.value = null;
        this.selectedTag.triggerParams.step_idx = null;
      } else if (value == 'step verification') {
        this.selectedTag.triggerParams.comparator = null;
        this.selectedTag.triggerParams.threshold = null;
        this.selectedTag.triggerParams.step_idx = null;
      }
    },
  },
};
</script>

<style>
.opc-tag-parameters-modal .ant-form-item {
  width: 95%;
}

.opc-tag-parameters-modal .ant-card {
  margin-bottom: 1rem;
}

.opc-tag-parameters-modal .ant-modal-footer {
  display: block;
}
</style>
