<template>
  <div class="py-4 px-2 p-md-4">
    <a-tabs id="iot-page-tabs">
      <a-tab-pane key="1" id="projector-tab">
        <template #tab>
          <span>
            Projector
          </span>
        </template>
        <projector-tab :activeDevices="activeDevices" />
      </a-tab-pane>
      <a-tab-pane key="2" id="barcode-tab">
        <template #tab>
          <span>
            Barcode
          </span>
        </template>
        <barcode :selectedTask="selectedTask" />
      </a-tab-pane>
      <a-tab-pane key="3" id="signal-tower-tab">
        <template #tab>
          <span>
            Signal Tower
          </span>
        </template>
        <signal-tower-tab />
      </a-tab-pane>
      <a-tab-pane key="4" id="signal-tower-tab">
        <template #tab>
          <span>
            OPC Tags
          </span>
        </template>
        <OPCTab :activeDevices="activeDevices" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import ProjectorTab from './ProjectorTab.vue';
import SignalTowerTab from './SignalTowerTab.vue';
import OPCTab from './Opc/OpcTab.vue';
import DeviceService from 'src/services/device';
import TaskService from 'src/services/tasks.js';
import { mapGetters } from 'vuex';
import Barcode from './Barcode.vue';
import { defineComponent } from 'vue';
import OPCTagsVue from '../../IoT/OPCTags.vue';
import { useActiveDevices } from '../../RecordData/composables/useActiveDevices';

export default defineComponent({
  components: {
    SignalTowerTab,
    ProjectorTab,
    Barcode,
    OPCTab
  },
  setup() {
    const { activeDevices } = useActiveDevices();

    return {
      activeDevices
    };
  },
  data() {
    return {
      orgName: localStorage.getItem('organization'),
      activeDevicesLocal: [],
      isPolled: false,
      activeDevicesPolling: null,
      activeDevicesSettings: [],
      activeDevicesStatus: {},
      enablePatliteTower: false,
      selectedDevice: {},
      activeDevicesdetails: []
    };
  },
  computed: {
    ...mapGetters(['selectedTask'])
  },

  watch: {
    activeDevicesLocal(devices) {
      if (!devices.length) return;
      this.getActiveDevicesDetails();
    }
  },

  beforeUnmount() {
    clearInterval(this.activeDevicesPolling);
  },
  methods: {
    getActiveDevicesDetails() {
      let tempActiveDevicesdetails = [];
      if (!this.activeDevicesLocal.length) return;
      this.activeDevicesLocal.map(async device => {
        const [error, data] = await DeviceService.getDeviceDetails(
          device,
          this.organization,
          false
        );
        if (error) return;
        tempActiveDevicesdetails.push({
          serial_number: device,
          display_name: data['display_name']
        });
        this.activeDevicesdetails = tempActiveDevicesdetails;
      });
    },

    async getActiveDevicesSettings() {
      if (!this.activeDevicesLocal.length) return;
      const devices = JSON.stringify(this.activeDevicesLocal);
      const [error, data] = await DeviceService.getSettingsOfActiveDevices(
        false,
        {
          devices: devices
        }
      );
      if (error) return;
      this.activeDevicesSettings = data;
      await this.getActiveDevicesStatuses(devices);
    },

    async getActiveDevicesStatuses(devices) {
      const payload = { Serial_number: devices };
      const [error, data] = await DeviceService.getStatusOfActiveDevices(
        false,
        payload
      );
      if (error) return;
      this.activeDevicesStatus = data;
    }
  }
});
</script>
<style></style>
